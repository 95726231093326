import "./TagManagement.scss";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Grid,
  Input,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Breadcrumb } from "../../../breadcrumb/components/Breadcrumb";
import { Order, TableHeadCell } from "../../../../shared/models/TableModels";
import DeleteIcon from "@mui/icons-material/Delete";
import { WorkflowUsed } from "../workflow-used/WorkflowUsed";
import EditIcon from "@mui/icons-material/Edit";
import {
  TableDataActionsModel,
  TagMgmtListModel,
} from "../models/TagManagementModal";
import { useAPI } from "../../../../shared/services/api/API";
import { SpinnerContext } from "../../../../shared/SpinnerContext";
import { OPTIVAL_CONSTANTS } from "../../../../shared/Constants";
import { TableLoading } from "../../../../shared/components/skeleton/table-loading/TableLoading";
import { tableSorting, updateURLParams } from "../../../../shared/utils/Utils";
import { TagManagementConstants, useTagManagamentUtil } from "../constants/TagManagementConstants";
import { setPassDataState } from "../../../../shared/reducers/PassDataReducer";
import { AppDispatch } from "../../../../shared/Store";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { AlertDialog } from "../../../../shared/components/alert-dialog/AlertDialog";

export const TagManagement = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { httpGet, httpDelete, handleAlertBar } = useAPI();
  const { isSpinnerShow } = useContext(SpinnerContext);
  const [toggleCollapse, setToggleCollapse] = useState<number | null>(null);
  const [collapseText, setCollapseText] = useState<string | null>(null);
  const [tableDataActions, setTableDataActions] = useState({
    page: 0,
    perPageRows: 10,
    order: "desc",
    orderBy: "name",
    searchText: "",
  } as TableDataActionsModel);
  const [tagMgmtList, setTagMgmtList] = useState<TagMgmtListModel[]>([]);
  const [filteredTagMgmtData, setFilteredTagMgmtData] = useState<
    TagMgmtListModel[]
  >([]);
  const [checkedTagManagement, setCheckedTagManagement] = useState<
  TagMgmtListModel[]
>([]);
  const [searchTagsText, setSearchTagsText] = useState("");
  const { getTagManagementHeaders } = useTagManagamentUtil();
  const { t } = useTranslation();
  const [isAlertDialog, setIsAlertDialog] = useState(false);
  const [isAlertDialogBulkDelete, setIsAlertDialogBulkDelete] = useState(false);
  const [tagId, setTagId] = useState<number>(0);

  const handleAlertDialogOpen = (tagId: number) => {
    setTagId(tagId);
    setIsAlertDialog(true);
  };

  const handleAlertDialogClose = () => {
    setTagId(0);
    setIsAlertDialog(false);
  };

  const handleAlertDialogOpenBulkDelete = () => {
    setIsAlertDialogBulkDelete(true);
  };

  const handleAlertDialogCloseBulkDelete = () => {
    setIsAlertDialogBulkDelete(false);
  };

  const backToOrganisation = () => {
    navigate("/management");
  };

  const goToAddTag = () => {
    navigate("/new-tags");
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const updateProperty = {
      perPageRows: parseInt(event.target.value, 10),
      page: 0,
    };
    setTableDataActions((prevState) => ({
      ...prevState,
      ...updateProperty,
    }));
  };

  const handlePaginationChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    setTableDataActions((prevState) => ({
      ...prevState,
      page: newPage,
    }));
  };

  const handleSorting = (property: string) => {
    const isAsc =
      tableDataActions.orderBy === property && tableDataActions.order === "asc";
    const updateProperty = {
      order: isAsc ? "desc" : ("asc" as Order),
      orderBy: property,
    };
    setTableDataActions((prevState) => ({
      ...prevState,
      ...updateProperty,
    }));
  };

  const handleSearchByTag = (value: string) => {
    if (!value) {
      setTagMgmtList([...tagMgmtList]);
    } else {
      const filteredData = tagMgmtList.filter((tagData: TagMgmtListModel) =>
        tagData.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredTagMgmtData(
        filteredData.slice(
          tableDataActions.page * tableDataActions.perPageRows,
          tableDataActions.page * tableDataActions.perPageRows +
            tableDataActions.perPageRows
        )
      );
    }
  };

  const fetchTagMgmtList = () => {
    const queryParams = {
      page: 0,
      size: 1000,
      sortColumn: "name",
      isDescending: true,
      searchString: "",
    };

    httpGet(OPTIVAL_CONSTANTS.API_URLS.TAG, queryParams).then(
      (response) => {
        if (response && response?.data && !response.data?.error) {
          response.data.map((e: any) => {
            e["worflowsUsedCount"] = e.workflowsUsed?.length;
          });
          setTagMgmtList(response.data);
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  };
  const handleCollapseOpen = (rowIndex: number, collapseFeature: string) => {
    setToggleCollapse(rowIndex);
    setCollapseText(collapseFeature);
  };

  useMemo(() => {
    const propertyType =
      tableDataActions.orderBy === "worflowsUsedCount" ? "number" : "string";

    const sortedList = tableSorting(
      tagMgmtList,
      tableDataActions.order,
      tableDataActions.orderBy,
      propertyType
    );
    setTagMgmtList(sortedList);
    setFilteredTagMgmtData(
      sortedList.slice(
        tableDataActions.page * tableDataActions.perPageRows,
        tableDataActions.page * tableDataActions.perPageRows +
          tableDataActions.perPageRows
      )
    );
  }, [tableDataActions, tagMgmtList]);

  useEffect(() => {
    handleSearchByTag(searchTagsText);
  }, [searchTagsText]);

  useEffect(() => {
    fetchTagMgmtList();
  }, []);

  // const handleAlertDialogOpen = (tagId: number) => {};
  // const handleDeleteWrapper = () => {
  //   handleDelete(tagId);
  // };

  const handleDeleteTag= async() => {
    const urlParams = {
      tagId: tagId
    };
    await httpDelete(
      updateURLParams(OPTIVAL_CONSTANTS.API_URLS.DELETE_TAG, urlParams),
    ).then(
      (response) => {
          if (response?.status === 200) {
            handleAlertBar(
              "success",
              t("opva.deleteTagSuccessMsg")
            );
            fetchTagMgmtList();
            handleAlertDialogClose();
          } 
          else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  };

  const handleCollapseClose = () => {
    setToggleCollapse(null);
    setCollapseText(null);
  };

  const ActionTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      border: "1px solid #dadde9",
      padding: "1rem",
    },
  }));

  function allTagManagementListChecked() {
    if (
      checkedTagManagement.length === 0 &&
      tagMgmtList.length === 0
    )
      return false;
    for (let tag of tagMgmtList) {
      const isPresent = checkedTagManagement.some(
        (data: any) => data.id === tag.id
      );

      if (!isPresent) {
        return false;
      }
    }
    return true;
  }

  const handleAllCheckBoxForTagMgmtList = (event: any) => {
    if (event.target.checked) {
      const uniqueTag: TagMgmtListModel[] = [];
      tagMgmtList.forEach((tag: TagMgmtListModel) => {
        const index = checkedTagManagement.findIndex(
          (checkedTag: TagMgmtListModel) =>
            checkedTag.id === tag.id
        );
        if (index === -1) {
          uniqueTag.push(tag);
        }
      });
      setCheckedTagManagement([
        ...checkedTagManagement,
        ...uniqueTag,
      ]);
    } else {
      setCheckedTagManagement([]);
    }
  };

  const checkedTagManagementList = (data: any): boolean => {
    if (!checkedTagManagement.length) {
      return false;
    }
    const arr = checkedTagManagement.filter(
      (res: any) => res.id === data.id
    );
    return arr.length > 0 ? true : false;
  };

  const handleCheckBoxForTag = (event: any, data: any) => {
    if (event.target.checked) {
      setCheckedTagManagement([...checkedTagManagement, data]);
    } else {
      const index = checkedTagManagement.findIndex(
        (workflow: any) => workflow.id === data.id
      );
      checkedTagManagement.splice(index, 1);

      setCheckedTagManagement([...checkedTagManagement]);
    }
  };

  const deleteTagInBulk = async() => {
    const payload = checkedTagManagement?.map((tag: any) => tag?.id);
    await httpDelete(
      OPTIVAL_CONSTANTS.API_URLS.DELETE_TAG_BATCH, payload,
    ).then(
      (response) => {
          if (response?.status === 200) {
            handleAlertBar(
              "success",
              t("opva.deleteTagSuccessMsg")
            );
            fetchTagMgmtList();
            handleAlertDialogCloseBulkDelete();
          } 
          else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  }

  return (
    <div>
      <div className="tag-management px-5 pt-2 mb-3">
        <Breadcrumb
          pathList={[
            { name: t("opva.management"), path: "management" },
            { name: t("opva.tagManagement"), path: "tags-management" },
          ]}
        />
        <Grid container alignItems="center" className="pt-2 pb-4">
          <Grid item xs md lg={6} className="d-flex align-items-center">
            <Link
              title={t("opva.backToManagement")}
              className="me-2"
              onClick={backToOrganisation}
            >
              <KeyboardArrowLeftIcon fontSize="large" />
            </Link>

            <Typography variant="h3" className="mb-0">
              {t("opva.tagsManagement")}
            </Typography>
          </Grid>
          <Grid
            item
            xs
            md
            lg={6}
            className="d-flex align-items-center justify-content-end"
          >
            <Input
              id="searchByTag"
              placeholder={t("opva.searchByTag")}
              value={searchTagsText}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon color="primary" />
                </InputAdornment>
              }
              endAdornment={
                searchTagsText && (
                  <InputAdornment position="end">
                    <Link title={t("opva.clear")}>
                      <CloseIcon
                        fontSize="small"
                        onClick={() => setSearchTagsText("")}
                      />
                    </Link>
                  </InputAdornment>
                )
              }
              aria-describedby="standard-weight-helper-text"
              onChange={(event) => setSearchTagsText(event.target.value)}
            />
            <Button
              variant="contained"
              size="medium"
              className="px-4 ms-4"
              onClick={goToAddTag}
            >
              {t("opva.addNewTag")}
            </Button>
            <Button
              variant="contained"
              size="medium"
              className="px-4 ms-4"
              onClick={handleAlertDialogOpenBulkDelete}
              disabled={checkedTagManagement?.length === 0}
            >
              {t("Delete Tags")}
            </Button>
          </Grid>
        </Grid>
      </div>
      <Box
        sx={{ width: "100%", typography: "body1" }}
        className="tag-mgmt-content px-5"
      >
        <TableContainer className="table-container">
          <Table
            stickyHeader
            sx={{ width: "100%" }}
            size="medium"
            aria-label="Group Management table"
          >
            <TableHead>
              <TableRow key="tag-mgmt-table-header">
              <TableCell size="small" padding="checkbox">
                <Checkbox
                      checked={allTagManagementListChecked()}
                      onChange={(e) => handleAllCheckBoxForTagMgmtList(e)}
                  />
                </TableCell>
                {getTagManagementHeaders().map(
                  (headCell: TableHeadCell) => (
                    <TableCell
                      key={headCell.id}
                      sortDirection={
                        tableDataActions.orderBy === headCell.sortingProperty
                          ? tableDataActions.order
                          : false
                      }
                      align={headCell.position}
                      sx={{
                        width: headCell.width + "%",
                      }}
                    >
                      {headCell.sorting ? (
                        <TableSortLabel
                          active={
                            tableDataActions.orderBy ===
                            headCell.sortingProperty
                          }
                          direction={
                            tableDataActions.orderBy ===
                            headCell.sortingProperty
                              ? tableDataActions.order
                              : "asc"
                          }
                          onClick={(event) =>
                            handleSorting(headCell.sortingProperty)
                          }
                        >
                          {headCell.label}
                          {tableDataActions.orderBy === headCell.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {tableDataActions.order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        headCell.label
                      )}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            {isSpinnerShow ? (
              <TableLoading
                column={getTagManagementHeaders().length}
              />
            ) : (
              <TableBody>
                {filteredTagMgmtData && filteredTagMgmtData.length > 0 ? (
                  filteredTagMgmtData.map(
                    (data: TagMgmtListModel, index: number) => (
                      <Fragment key={index}>
                        <TableRow key={index}>
                        <TableCell size="small" padding="checkbox">
                          <Checkbox
                            checked={checkedTagManagementList(data)}
                            onChange={(e) => handleCheckBoxForTag(e, data)}
                          />
                        </TableCell>
                          <TableCell>{data.name}</TableCell>
                          <TableCell>{data?.description}</TableCell>
                          <TableCell>
                            {data.createdAt &&
                              new Date(data.createdAt).toLocaleDateString()}
                          </TableCell>
                          <TableCell>{data?.createdBy}</TableCell>
                          <TableCell align="center">
                            <Link
                              underline="none"
                              className="fw-bold"
                              onClick={() =>
                                handleCollapseOpen(
                                  index,
                                  TagManagementConstants.WORKFLOW_USED_OF_THIS_TAG
                                )
                              }
                            >
                              {data?.worflowsUsedCount}
                            </Link>
                          </TableCell>
                          {/* <TableCell align="center">{data?.workflowsUsed}</TableCell> */}
                          <TableCell align="center">
                            <ActionTooltip
                              arrow
                              slotProps={{
                                popper: {
                                  sx: {
                                    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                      {
                                        marginTop: "0px",
                                      },
                                  },
                                },
                              }}
                              // onClick={(e) => {
                              //   dispatch(setPassDataState(data));
                              //   navigate("/edit-tags?id=" + data.id, {
                              //     state: { tagData: data },
                              //   });
                              // }}
                              title={
                                <div className="d-flex flex-column">
                                  <Link
                                    title="Edit"
                                    underline="none"
                                    className="d-flex align-items-center"
                                    onClick={(e) => {
                                      dispatch(setPassDataState(data));
                                      navigate("/edit-tags?id=" + data.id, {
                                        state: { tagData: data },
                                      });
                                    }}
                                  >
                                    <EditIcon />
                                    <Typography
                                      variant="labelNormal"
                                      className="ps-2"
                                      onClick={(e) => {
                                        dispatch(setPassDataState(data));
                                        navigate("/edit-tags?id=" + data.id, {
                                          state: { tagData: data },
                                        });
                                      }}
                                    >
                                      {t("opva.viewEdit")}
                                    </Typography>
                                  </Link>
                                  <Link
                                      component="button"
                                      color="error"
                                      title={t("opva.delete")}
                                      underline="none"
                                      className="d-flex align-items-center mt-3"
                                      onClick={() => handleAlertDialogOpen(data.id)}
                                    >
                                      <DeleteIcon />{" "}
                                      <Typography
                                        color="error"
                                        variant="labelNormal"
                                        className="ps-2"
                                      >
                                        {t("opva.delete")}
                                      </Typography>
                                    </Link>
                                </div>
                              }
                            >
                              <Link>
                                <MoreHorizIcon />
                              </Link>
                            </ActionTooltip>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={8} className="p-0">
                            <Collapse
                              in={index === toggleCollapse}
                              timeout="auto"
                              unmountOnExit
                            >
                              {collapseText ===
                                TagManagementConstants.WORKFLOW_USED_OF_THIS_TAG && (
                                <WorkflowUsed
                                  tagId={data.id}
                                  workflowsUsed={data?.workflowsUsed}
                                  collapseClose={handleCollapseClose}
                                />
                              )}
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    )
                  )
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={
                        getTagManagementHeaders().length
                      }
                    >
                      {searchTagsText
                        ? t("opva.searchTagDataNotAvailable.")
                        : t("opva.noTagDataAvailable")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <div className="d-flex align-items-center justify-content-end">
          <TablePagination
            component="div"
            className="pagination-container mb-4"
            count={tagMgmtList.length}
            page={tableDataActions.page}
            rowsPerPage={tableDataActions.perPageRows}
            onPageChange={handlePaginationChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
        <AlertDialog
          open={isAlertDialog}
          title={t("opva.deleteTag")}
          description={t("opva.confirmationMsgDeleteTag")}
          submitBtnText={t("opva.delete")}
          handleSubmit={handleDeleteTag}
          handleClose={handleAlertDialogClose}
        ></AlertDialog>
        <AlertDialog
          open={isAlertDialogBulkDelete}
          title={t("opva.deleteTag")}
          description={t("opva.confirmationMsgDeleteTag")}
          submitBtnText={t("opva.delete")}
          handleSubmit={deleteTagInBulk}
          handleClose={handleAlertDialogCloseBulkDelete}
        ></AlertDialog>
      </Box>
    </div>
  );
};
