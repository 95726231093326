import {
  Fragment,
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Box,
  Grid,
  Typography,
  InputAdornment,
  Input,
  Link,
  Button,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Tooltip,
  TableSortLabel,
  Collapse,
  styled,
  TooltipProps,
  tooltipClasses,
  Checkbox,
} from "@mui/material";
import "./NewManagement.scss";
import { Breadcrumb } from "../../../breadcrumb/components/Breadcrumb";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  TableDataActionsModel,
  UserMgmtListModel,
} from "../../models/UserManagement";
import { TableLoading } from "../../../../shared/components/skeleton/table-loading/TableLoading";
import { OPTIVAL_CONSTANTS } from "../../../../shared/Constants";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router";
import { useAPI } from "../../../../shared/services/api/API";
import { tableSorting, updateURLParams } from "../../../../shared/utils/Utils";
import { AuthenticationContext } from "../../../../shared/Contexts";
import { SpinnerContext } from "../../../../shared/SpinnerContext";
import { Order, TableHeadCell } from "../../../../shared/models/TableModels";
import { visuallyHidden } from "@mui/utils";
import { UserManagementConstants, useManagamentUtil } from "../../constants/UserManagementConstant";
import { AlertDialog } from "../../../../shared/components/alert-dialog/AlertDialog";
import { SkillsDetail } from "../skills-detail/SkillsDetail";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../shared/Store";
import { setPassDataState } from "../../../../shared/reducers/PassDataReducer";
import { useTranslation } from "react-i18next";

// const generateRandomUserData = () => {
//   return {
//     name: "John Doe",
//     employeeId: `MA${Math.floor(Math.random() * 10000)}`,
//     email: "john.doe@gmail.com",
//     phoneNumber: "+91-99999-99999",
//     lastActive: "30 Nov 2023",
//   };
// };

// interface UserCardProps {
//   user: UserData;
// }
// const UserCard: React.FC<UserCardProps & { onDelete: () => void }> = ({
//   user,
//   onDelete,
// }) => (
//   <Box className="Card-Content" style={{ margin: "20px" }}>
//     <Box
//       style={{
//         display: "flex",
//         flexDirection: "row",
//       }}
//     >
//       <div className="profile-image" style={{ margin: 10 }}></div>
//       <div style={{ margin: 10, display: "flex", flexDirection: "column" }}>
//         <Typography variant="h6">{user.name}</Typography>
//         <Typography
//           variant="bodyNormal"
//           style={{ fontSize: "0.625rem", fontStyle: "normal", fontWeight: 400 }}
//         >
//           Employee ID:{" "}
//           <span
//             style={{
//               color: "var(--Gable-Green, #1C2E36)",
//               fontFamily: "Mulish",
//               fontSize: "0.625rem",
//               fontStyle: "normal",
//               fontWeight: 600,
//               lineHeight: "170%",
//             }}
//           >
//             {user.employeeId}
//           </span>
//         </Typography>
//       </div>
//     </Box>
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "row",
//         justifyContent: "space-between",
//       }}
//     >
//       <div style={{ marginLeft: 10, display: "flex", flexDirection: "column" }}>
//         <Typography
//           variant="labelNormal"
//           style={{
//             fontSize: " 0.625rem",
//             color: "var(--River-Bed, #45545A)",
//             fontStyle: "normal",
//             fontWeight: 400,
//           }}
//         >
//           Email
//         </Typography>
//         <Typography variant="bodyNormal" style={{ fontSize: " 0.625rem" }}>
//           {user.email}
//         </Typography>
//       </div>
//       <div style={{ marginLeft: 10, display: "flex", flexDirection: "column" }}>
//         <Typography
//           variant="labelNormal"
//           style={{
//             fontSize: " 0.625rem",
//             color: "var(--River-Bed, #45545A)",
//             fontStyle: "normal",
//             fontWeight: 400,
//           }}
//         >
//           Phone Number
//         </Typography>
//         <Typography variant="bodyNormal" style={{ fontSize: " 0.625rem" }}>
//           {user.phoneNumber}
//         </Typography>
//       </div>
//     </div>
//     <div style={{ margin: 10, display: "flex", flexDirection: "column" }}>
//       <Typography
//         variant="labelNormal"
//         style={{
//           fontSize: " 0.625rem",
//           color: "var(--River-Bed, #45545A)",
//           fontStyle: "normal",
//           fontWeight: 400,
//         }}
//       >
//         Last active on
//       </Typography>
//       <Typography variant="bodyNormal" style={{ fontSize: " 0.625rem" }}>
//         {user.lastActive}
//       </Typography>
//     </div>

//     <div style={{ display: "flex", flexDirection: "row" }}>
//       <div
//         className="editContainer"
//         style={{
//           display: "flex",
//           flexDirection: "row",
//           justifyContent: "center",
//         }}
//       >
//         <img
//           src="images/EditBlack.svg"
//           title="EditIcon"
//           alt="Edit Icon"
//           width={24}
//           height={24}
//           className="me-3"
//         />
//         <Typography>Edit</Typography>
//       </div>
//       <div
//         className="DeleteContainer"
//         style={{
//           display: "flex",
//           flexDirection: "row",
//           justifyContent: "center",
//           cursor: "pointer",
//         }}
//         onClick={onDelete}
//       >
//         <img
//           src="images/DeleteBlack.svg"
//           title="DeleteIcon"
//           alt="Delete Icon"
//           width={24}
//           height={24}
//           className="me-3"
//         />
//         <Typography>Delete</Typography>
//       </div>
//     </div>
//   </Box>
// );

export const NewManagementWorkflow = () => {
  const { t } = useTranslation();
  const { authUserDetails } = useContext(AuthenticationContext);
  const dispatch: AppDispatch = useDispatch();
  const { httpGet, httpDelete, handleAlertBar } = useAPI();
  const [searchUserText, setSearchUserText] = useState("");
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(UserManagementConstants.ALL_USERS);
  const { isSpinnerShow } = useContext(SpinnerContext);
  const [isAlertDialog, setIsAlertDialog] = useState(false);
  const [isAlertDialogBulkDelete, setIsAlertDialogBulkDelete] = useState(false);
  const [userId, setUserId] = useState<number>(0);
  const [tableDataActions, setTableDataActions] = useState({
    page: 0,
    perPageRows: 25,
    order: "desc",
    orderBy: "name",
    searchText: "",
  } as TableDataActionsModel);
  const [tabList, setTabList] = useState([]);
  const [usersData, setUsersData] = useState<UserMgmtListModel[]>([]);
  const [filteredUsersData, setFilteredUsersData] = useState<
    UserMgmtListModel[]
  >([]);
  const [checkedUserManagement, setCheckedUserManagement] = useState<
  UserMgmtListModel[]
  >([]);
  const [toggleCollapse, setToggleCollapse] = useState<number | null>(null);
  const [collapseText, setCollapseText] = useState<string | null>(null);

  const ActionTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      border: "1px solid #dadde9",
      padding: "1rem",
    },
  }));
  const { getUserManagementHeaders } = useManagamentUtil();

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const updateProperty = {
      perPageRows: parseInt(event.target.value, 25),
      page: 0,
    };
    setTableDataActions((prevState) => ({
      ...prevState,
      ...updateProperty,
    }));
  };

  const handlePaginationChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    setTableDataActions((prevState) => ({
      ...prevState,
      page: newPage,
    }));
  };

  const handleCollapseOpen = (rowIndex: number, collapseFeature: string) => {
    setToggleCollapse(rowIndex);
    setCollapseText(collapseFeature);
  };

  const handleCollapseClose = () => {
    setToggleCollapse(null);
    setCollapseText(null);
  };

  const handleAlertDialogOpen = (userId: number) => {
    setUserId(userId);
    setIsAlertDialog(true);
  };

  const handleAlertDialogClose = () => {
    setUserId(0);
    setIsAlertDialog(false);
  };

  const handleAlertDialogOpenBulkDelete = () => {
    setIsAlertDialogBulkDelete(true);
  };

  const handleAlertDialogCloseBulkDelete = () => {
    setIsAlertDialogBulkDelete(false);
  };

  function allUserManagementListChecked() {
    if (
      checkedUserManagement.length === 0 &&
      usersData.length === 0
    )
      return false;
    for (let user of usersData) {
      const isPresent = checkedUserManagement.some(
        (data: any) => data.id === user.id
      );

      if (!isPresent) {
        return false;
      }
    }
    return true;
  }

  const handleAllCheckBoxForUserMgmtList = (event: any) => {
    if (event.target.checked) {
      const uniqueUser: UserMgmtListModel[] = [];
      usersData.forEach((user: UserMgmtListModel) => {
        const index = checkedUserManagement.findIndex(
          (checkedUser: UserMgmtListModel) =>
            checkedUser.id === user.id
        );
        if (index === -1) {
          uniqueUser.push(user);
        }
      });
      setCheckedUserManagement([
        ...checkedUserManagement,
        ...uniqueUser,
      ]);
    } else {
      setCheckedUserManagement([]);
    }
  };

  const checkedUserManagementList = (data: any): boolean => {
    if (!checkedUserManagement.length) {
      return false;
    }
    const arr = checkedUserManagement.filter(
      (res: any) => res.id === data.id
    );
    return arr.length > 0 ? true : false;
  };

  const handleCheckBoxForUser = (event: any, data: any) => {
    if (event.target.checked) {
      setCheckedUserManagement([...checkedUserManagement, data]);
    } else {
      const index = checkedUserManagement.findIndex(
        (workflow: any) => workflow.id === data.id
      );
      checkedUserManagement.splice(index, 1);

      setCheckedUserManagement([...checkedUserManagement]);
    }
  };

  const deleteUserInBulk = async() => {
    const payload = checkedUserManagement?.map((group: any) => group?.id);
    await httpDelete(
      OPTIVAL_CONSTANTS.API_URLS.DELETE_MULTIPLE_EMP_DATA, payload,
    ).then(
      (response) => {
          if (response?.status === 200) {
            handleAlertBar(
              "success",
              t("opva.deleteUserSuccessMsg")
            );
            getEmployeesByOrganisation();
            handleAlertDialogCloseBulkDelete();
          } 
          else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  }

  const handleSorting = (property: string) => {
    const isAsc =
      tableDataActions.orderBy === property && tableDataActions.order === "asc";
    const updateProperty = {
      order: isAsc ? "desc" : ("asc" as Order),
      orderBy: property,
    };
    setTableDataActions((prevState) => ({
      ...prevState,
      ...updateProperty,
    }));
  };

  const handleSearchByName = (value: string) => {
    if (!value) {
      setUsersData([...usersData]);
    } else {
      const filteredData = usersData.filter(
        (userData: UserMgmtListModel) =>
          userData.firstName.toLowerCase().includes(value.toLowerCase()) ||
          userData.mail.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredUsersData(
        filteredData.slice(
          tableDataActions.page * tableDataActions.perPageRows,
          tableDataActions.page * tableDataActions.perPageRows +
            tableDataActions.perPageRows
        )
      );
    }
  };

  const goToAddUser = () => {
    navigate("/newUser");
  };

  // const handleEditUser = (rowData: UserMgmtListModel) => {
  //   navigate(`/edituser?id=${rowData.id}`, {
  //     state: { rowData },
  //   });
  // };

  const handleTabChange = (event: SyntheticEvent, newValue: any) => {
    setTabValue(newValue);
    if (newValue === UserManagementConstants.ALL_USERS) {
      getEmployeesByOrganisation();
    } else {
      getEmployeesByOrganisation(newValue);
    }
  };

  const backToOrganisation = () => {
    navigate("/management");
  };

  const handleDelete = async () => {
    const updatedUsersData: UserMgmtListModel[] = [...usersData];
    const urlParams = {
      organizationId: authUserDetails.organizationId,
      employeeId: userId,
    };
    await httpDelete(
      updateURLParams(
        OPTIVAL_CONSTANTS.API_URLS.DELETE_EMPLOYEE_DATA,
        urlParams
      )
    ).then(
      (response) => {
        if (response?.status === 200) {
          handleAlertBar(
            "success",
            t("opva.deleteUserSuccessMsg")
          );
          getEmployeesByOrganisation();
          handleAlertDialogClose();
        } 
        else {
        handleAlertBar("error", t("opva.serviceUnavailable"));
      }
      },
      (error) => {}
    );
  };
  // const handlePaginationChange = (
  //   event: React.ChangeEvent<unknown>,
  //   value: number
  // ): void => {
  //   setPage(value);
  // };
  // const getCount = (): number => {
  //   return Math.ceil((tableRowList.length - 1) / perPageRows);
  // };

  const getEmployeesByOrganisation = async (roleId?: any) => {
    const urlParams = {
      organizationId: authUserDetails.organizationId,
    };
    const queryParams = {
      roleIds: roleId,
      page: 0,
      size: 1000,
      sortColumn: "firstName",
      isDescending: true,
      searchString: "",
    };
    await httpGet(
      updateURLParams(
        OPTIVAL_CONSTANTS.API_URLS.GET_EMPLOYEES_BY_ORGANISATION,
        urlParams
      ),
      queryParams
    ).then(
      (response: any) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            console.log("employees detail response-->", response.data);
            response.data.map((e: any) => {
              e["skillCount"] = e.skillNames?.length;
            });
            setUsersData(response.data);
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error: any) => {
        handleAlertBar(
          "error",
          error.message || t("opva.serviceUnavailable")
        );
      }
    );
  };
  const getRoles = () => {
    httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_ROLES).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            console.log("roles list response-->", response.data);
            const roleList = response?.data?.filter(
              (e: any) => !e.applicationRole
            );
            setTabList(roleList);
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {
        handleAlertBar(
          "error",
          error.message || t("opva.serviceUnavailable")
        );
      }
    );
  };

  useMemo(() => {
    if (authUserDetails && authUserDetails.organizationId) {
      getEmployeesByOrganisation();
    }
  }, [authUserDetails]);

  useEffect(() => {
    handleSearchByName(searchUserText);
  }, [searchUserText]);

  useMemo(() => {
    const propertyType =
      tableDataActions.orderBy === "skillCount" ? "number" : "string";
    const sortedList = tableSorting(
      usersData,
      tableDataActions.order,
      tableDataActions.orderBy,
      propertyType
    );
    setUsersData(sortedList);
    setFilteredUsersData(
      sortedList.slice(
        tableDataActions.page * tableDataActions.perPageRows,
        tableDataActions.page * tableDataActions.perPageRows +
          tableDataActions.perPageRows
      )
    );
  }, [tableDataActions, usersData]);

  useEffect(() => {
    if (authUserDetails && authUserDetails.organizationId) {
      getEmployeesByOrganisation();
    }
    getRoles();
  }, []);
  return (
    <div>
      <div className="user-management px-5 pt-2 mb-3">
        <Breadcrumb
          pathList={[
            { name: t("opva.management"), path: "management" },
            { name: t("opva.userManagement"), path: "newmanagement" },
          ]}
        />
        <Grid container alignItems="center" className="pt-2 pb-4">
          <Grid item xs md lg={6} className="d-flex align-items-center">
            <Link
              component="button"
              title={t("opva.backToManagement")}
              className="me-2"
              onClick={backToOrganisation}
            >
              <KeyboardArrowLeftIcon fontSize="large" />
            </Link>
            <Typography variant="h3" className="mb-0">
              {t("opva.userManagement")}
            </Typography>
          </Grid>
          <Grid
            item
            xs
            md
            lg={6}
            className="d-flex align-items-center justify-content-end"
          >
            <Input
              id="search"
              placeholder={t("opva.searchByUser")}
              value={searchUserText}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon color="primary" />
                </InputAdornment>
              }
              endAdornment={
                searchUserText && (
                  <InputAdornment position="end">
                    <Link component="button" title={t("opva.clear")}>
                      <CloseIcon
                        fontSize="small"
                        onClick={() => setSearchUserText("")}
                      />
                    </Link>
                  </InputAdornment>
                )
              }
              aria-describedby="standard-weight-helper-text"
              onChange={(e) => setSearchUserText(e.target.value)}
            />
            {/* <img
                  src="images/Vector.svg"
                  title="ExportIcon"
                  alt="Export Icon"
                  width={20}
                  height={20}
                  className="me-3"
                /> */}
            <Button
              variant="contained"
              size="medium"
              className="px-4 ms-4"
              onClick={goToAddUser}
            >
              {t("opva.addNewUser")}
            </Button>
            <Button
              variant="contained"
              size="medium"
              className="px-4 ms-4"
              onClick={handleAlertDialogOpenBulkDelete}
              disabled={checkedUserManagement?.length === 0}
            >
              {t("Delete Users")}
            </Button>
          </Grid>
        </Grid>
      </div>
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {usersData.map((user, index) => (
          <UserCard
            key={index}
            user={user}
            onDelete={() => handleDelete(index)}
          />
        ))}
      </div> */}
      <Box
        sx={{ width: "100%", typography: "body1" }}
        className="user-management-content px-5"
      >
        <Grid container alignItems="center">
          <Grid container>
            <Grid item xs={10.7} md={10.7}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                className="tabs-container"
              >
                <Tab
                  value={UserManagementConstants.ALL_USERS}
                  label={UserManagementConstants.ALL_USERS}
                  sx={{ textTransform: "none" }}
                />
                {tabList.map((data: any) => (
                  <Tab
                    key={data?.name}
                    value={data?.id}
                    label={data?.name}
                    sx={{ textTransform: "none" }}
                  />
                ))}
              </Tabs>
            </Grid>
            <Grid item xs={1.2} md={1.2}>
              <Typography variant="bodySmall" className="fw-bold mt-2">
                {usersData.length} {t("opva.users")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <TableContainer className="table-container">
          <Table
            stickyHeader
            sx={{ width: "100%" }}
            size="medium"
            aria-label="User Management table"
          >
            <TableHead>
              <TableRow key="user-mgmt-table-header">
              <TableCell size="small" padding="checkbox">
                <Checkbox
                      checked={allUserManagementListChecked()}
                      onChange={(e) => handleAllCheckBoxForUserMgmtList(e)}
                  />
                </TableCell>
                {getUserManagementHeaders().map((headCell: TableHeadCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={
                      tableDataActions.orderBy === headCell.sortingProperty
                        ? tableDataActions.order
                        : false
                    }
                    align={headCell.position}
                    // className="px-2"
                    sx={{
                      width: headCell.width + "%",
                    }}
                  >
                    {headCell.sorting ? (
                      <TableSortLabel
                        active={
                          tableDataActions.orderBy === headCell.sortingProperty
                        }
                        direction={
                          tableDataActions.orderBy === headCell.sortingProperty
                            ? tableDataActions.order
                            : "asc"
                        }
                        onClick={(event) =>
                          handleSorting(headCell.sortingProperty)
                        }
                      >
                        {headCell.label}
                        {tableDataActions.orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {tableDataActions.order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {isSpinnerShow ? (
              <TableLoading column={getUserManagementHeaders().length} />
            ) : (
              <TableBody>
                {filteredUsersData && filteredUsersData.length > 0 ? (
                  filteredUsersData.map(
                    (data: UserMgmtListModel, index: number) => (
                      <Fragment key={index}>
                        <TableRow>
                        <TableCell size="small" padding="checkbox">
                          <Checkbox
                            checked={checkedUserManagementList(data)}
                            onChange={(e) => handleCheckBoxForUser(e, data)}
                          />
                        </TableCell>
                          <TableCell>{data?.firstName}</TableCell>
                          <TableCell>{data?.employeeId}</TableCell>
                          <TableCell>{data?.mail}</TableCell>
                          <TableCell>
                            {data.assignedRoles?.length > 0
                              ? data.assignedRoles
                                  .map((e) => e.name)
                                  .join(" , ")
                              : "-"}
                          </TableCell>
                          <TableCell>{data?.status}</TableCell>
                          <TableCell>
                            <Link
                              underline="none"
                              component="button"
                              className="fw-bold"
                              onClick={() =>
                                handleCollapseOpen(
                                  index,
                                  UserManagementConstants.SKILLS_OF_THIS_USER
                                )
                              }
                            >
                              {data?.skillCount}
                            </Link>
                          </TableCell>
                          <TableCell align="center">
                            <ActionTooltip
                              arrow
                              slotProps={{
                                popper: {
                                  sx: {
                                    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                      {
                                        marginTop: "0px",
                                      },
                                  },
                                },
                              }}
                              title={
                                <div className="d-flex flex-column">
                                  <Link
                                    // component="button"
                                    title="Edit"
                                    underline="none"
                                    className="d-flex align-items-center"
                                    onClick={(e) => {
                                      dispatch(setPassDataState(data));
                                      navigate("/edituser?userId=" + data.id, {
                                        state: { userData: data },
                                      });
                                    }}
                                  >
                                    <EditIcon />
                                    <Typography
                                      variant="labelNormal"
                                      className="ps-2"
                                      onClick={(e) => {
                                        dispatch(setPassDataState(data));
                                        navigate(
                                          "/edituser?userId=" + data.id,
                                          {
                                            state: { userData: data },
                                          }
                                        );
                                      }}
                                    >
                                      {t("opva.viewEdit")}
                                    </Typography>
                                  </Link>
                                  <Link
                                  component="button"
                                  color="error"
                                  title={t("opva.delete")}
                                  underline="none"
                                  className="d-flex align-items-center mt-3"
                                  onClick={() =>
                                    handleAlertDialogOpen(data.id)
                                  }
                                >
                                  <DeleteIcon />{" "}
                                  <Typography
                                    color="error"
                                    variant="labelNormal"
                                    className="ps-2"
                                  >
                                    {t("opva.delete")}
                                  </Typography>
                                </Link>
                                </div>
                              }
                            >
                              <Link component="button">
                                <MoreHorizIcon />
                              </Link>
                            </ActionTooltip>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={8} className="p-0">
                            <Collapse
                              in={index === toggleCollapse}
                              timeout="auto"
                              unmountOnExit
                            >
                              {collapseText ===
                                UserManagementConstants.SKILLS_OF_THIS_USER && (
                                <SkillsDetail
                                  userId={data.id}
                                  skillNames={data?.skillNames}
                                  collapseClose={handleCollapseClose}
                                />
                              )}
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    )
                  )
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={getUserManagementHeaders().length}
                    >
                      {searchUserText
                        ? t("opva.searchUserNotAvailable")
                        : t("opva.noUserDataAvailable")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>
      <div className="d-flex align-items-center justify-content-end px-5">
        <TablePagination
          sx={{
            ".MuiTablePagination-displayedRows": {
              "marginTop": "1em",
              "marginBottom": "1em",
            },
            ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
              {
                "marginTop": "1em",
                "marginBottom": "1em",
              },
          }}
          component="div"
          className="pagination-container mb-4"
          count={usersData.length}
          page={
            !usersData.length || usersData.length <= 0
              ? 0
              : tableDataActions.page
          }
          rowsPerPage={tableDataActions.perPageRows}
          onPageChange={handlePaginationChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      <AlertDialog
        open={isAlertDialog}
        title={t("opva.deleteUser")}
        description={t("opva.confirmationMsgDeleteUser")}
        submitBtnText={t("opva.delete")}
        handleSubmit={handleDelete}
        handleClose={handleAlertDialogClose}
      ></AlertDialog>
      <AlertDialog
          open={isAlertDialogBulkDelete}
          title={t("opva.deleteUser")}
        description={t("opva.confirmationMsgDeleteUser")}
        submitBtnText={t("opva.delete")}
          handleSubmit={deleteUserInBulk}
          handleClose={handleAlertDialogCloseBulkDelete}
        ></AlertDialog>
    </div>
  );
};
