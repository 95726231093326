import { Button, Chip, Divider, Grid, Link, TextField, Typography } from '@mui/material';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import React, { useContext, useEffect, useState } from 'react'
import { useAPI } from '../../../../../../shared/services/api/API';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthenticationContext } from '../../../../../../shared/Contexts';
import { useTranslation } from 'react-i18next';
import { updateURLParams } from '../../../../../../shared/utils/Utils';
import { OPTIVAL_CONSTANTS } from '../../../../../../shared/Constants';
import { Breadcrumb } from '../../../../../breadcrumb/components/Breadcrumb';
import { DownloadExcelButton } from '../../../../../../shared/components/Download/DownloadExcelButton';
import { SkillsContext } from '../../../../contexts/SkillsContext';
import { SkillsConstants } from '../../../../models/SkillsConstants';

export const CreateNewSkills = (Props: any) => {
  const { httpPost, httpPut, handleAlertBar } = useAPI();
  const location = useLocation();
  const navigate = useNavigate();
  const { updateValidateLessMore } = useContext(SkillsContext);
  const queryParams = new URLSearchParams(location.search);
  const skillId = queryParams.get("skillId");
  const skillData = location?.state?.skillData;
  const { authUserDetails } = useContext(AuthenticationContext);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [errorMsg, setErrorMsg] = useState("");
  const handleChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const { t } = useTranslation();

  const handleUploadBoxDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleUploadBoxDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const dropFiles = e.dataTransfer.files[0];
    if (dropFiles.type.includes("sheet")) {
      setSelectedFile(dropFiles);
      setErrorMsg("");
    } else {
      setErrorMsg("Please Upload an excel sheet");
      setSelectedFile(null);
    }
  };

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
    }
  };

  const previousButton = () => {
    navigate("/skillz");
  };

  const handleRemoveClick = () => {
    setSelectedFile(null);
  };
  const header = ["name*", "description*"];

  const createSkillsData = async (param: any) => {
    await httpPost(OPTIVAL_CONSTANTS.API_URLS.CREATE_SKILLS, param).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            previousButton();
            handleAlertBar("success", t("opva.skillCreateMsg"));
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  };

  const updateSkillsData = async (param: any) => {
    const urlParams = {
      skillId: skillId,
    };
    await httpPut(
      updateURLParams(OPTIVAL_CONSTANTS.API_URLS.UPDATE_SKILLS, urlParams),
      param
    ).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            previousButton();
            handleAlertBar("success", t("opva.skillUpdateMsg"));
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  };

  const handleBulkSkill = async () => {
    const formData = new FormData();
    if (selectedFile) {
      formData.append("file", selectedFile);
    }
    await httpPost(
      OPTIVAL_CONSTANTS.API_URLS.ADD_BULK_SKILLS,
      formData
    ).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            handleAlertBar(
              "success",
              t("opva.skillCreateMsg")
            );
            previousButton();
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  };

  const handleCreateUpdateSkills = (event: any) => {
    event.preventDefault();
    if (isButtonEnabled()) {
      const param = {
        name: formData.name,
        description: formData.description,
      };
      console.log("Form submitted:", formData);
      setFormData((prevData) => ({ ...prevData }));
      if (Props?.isEdit) {
        updateSkillsData(param);
      } else {
        createSkillsData(param);
      }
    }
  };
  const isButtonEnabled = () => {
    return (
      formData.name.trim().length > 0 && formData.name.length < SkillsConstants.SKILLS_NAME_LENGTH && formData.description.trim().length > 0 && formData.description.length < 200
    );
  };
  useEffect(() => {
    if (location.state && location.state.skillData) {
      const { name, description } = location.state.skillData;
      setFormData({ name, description });
    } else {
      setFormData({ name: "", description: "" });
    }
  }, []);

  const backToSkillz = () => {
    navigate("/skillz");
    updateValidateLessMore(true);
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <div className="tag-management px-5 pt-2 mb-3">
          <Breadcrumb
            isFromOPVA={true}
            pathList={[
              { name: t("opva.skillz"), path: "skillz" },
              { name: Props.isEdit
                ? t("opva.updateSkill")
                : t("opva.addSkill"), path: "add-skills" },
            ]}
          />
          <Grid container alignItems="center" className="pt-2 pb-4">
            <Grid item xs md lg={6} className="d-flex align-items-center">
              <Link
                component="button"
                title={t("opva.backToSkill")}
                className="me-2"
                onClick={backToSkillz}
              >
                <KeyboardArrowLeftIcon fontSize="large" />
              </Link>
              <Typography variant="h4" className="mb-0">
                {Props.isEdit
                ? t("opva.updateSkill")
                : t("opva.addSkill")}
              </Typography>
            </Grid>
          </Grid>
        </div>
      <div className="tag-management-content px-4">
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6} className="m-4">
            <Grid item xs={12} sm={12} md={12} lg={12} className="mb-4">
              <Typography variant="bodyMedium" sx={{ marginBottom: 8 }}>
                {Props.isEdit
                 ? t("opva.updateSkill")
                 : t("opva.addIndiviualSkill")}
              </Typography>
            </Grid>
            <form onSubmit={(e) => e.preventDefault()} noValidate>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Typography variant="bodySmall" sx={{ marginBottom: 2 }}>
                  {t("opva.name")}
                </Typography>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <TextField
                    fullWidth
                    type="text"
                    name="name"
                    placeholder={t("opva.enterSkillName")}
                    variant="standard"
                    value={formData.name}
                    onChange={handleChange}
                    error={
                      formData.name.length >
                      SkillsConstants.SKILLS_NAME_LENGTH
                    }
                    helperText={
                      formData.name.length >
                        SkillsConstants.SKILLS_NAME_LENGTH && t("opva.skillNameLength")
                    }
                    sx={{
                      marginBottom: 4,
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} className="mb-2">
                <Typography variant="bodySmall">
                  {t("opva.description")}
                </Typography>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <TextField
                    fullWidth
                    type="text"
                    name="description"
                    variant="standard"
                    placeholder={t("opva.enterSkillDescription")}
                    value={formData.description}
                    onChange={handleChange}
                    error={
                      formData.description.length >
                      200
                    }
                    helperText={
                      formData.description.length >
                      200 && t("opva.skillDescriptionLength")
                    }
                    sx={{
                      marginBottom: 4,
                    }}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Button
                  size="large"
                  variant="contained"
                  href="skillz*-*"
                  disabled={!isButtonEnabled()}
                  onClick={handleCreateUpdateSkills}
                >
                  {Props?.isEdit
                    ? t("opva.updateSkill")
                    : t("opva.createSkill")}
                </Button>
              </Grid>
            </form>
          </Grid>
          {Props.isEdit ? (
            <></>
          ) : (
            <>
              <Grid item xs={12} sm={12} md={0.2} lg={0.2} className="m-2">
                <div className="user-dividers">
                  <Divider variant="middle" orientation="vertical" flexItem />
                </div>
              </Grid>

              <Grid
                container
                item
                xs={12}
                sm={12}
                md={3.8}
                lg={3.8}
                className="mt-4"
              >
                <Grid item xs={12} sm={12} md={12} lg={12} className="mb-4">
                  <Typography variant="bodyMedium" sx={{ marginBottom: 3 }}>
                    {t("opva.addBulkSkills")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="mb-4">
                  <Typography variant="bodyMedium" sx={{ marginBottom: 3 }}>
                  {t("opva.step1")}:   {t("opva.downloadFormatForBulkSkills")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5} >
                  <DownloadExcelButton
                    header={header}
                    listLength={3}
                    fileName={"BulkDatatemplate_skills.xlsx"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}> <div className="bulkuser-dividers m-3">
                  <Divider variant="middle" orientation="horizontal" flexItem />
                </div></Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="bodyMedium" sx={{ marginBottom: 3 }}>
                  {`${t("opva.step2")}: ${t("opva.fillUploadExcel")}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div
                    className="upload-box-container"
                    onDragOver={handleUploadBoxDragOver}
                    onDrop={handleUploadBoxDrop}
                  >
                    <input
                      type="file"
                      accept=".xlsx"
                      hidden
                      id="user-file-upload"
                      onChange={handleFileChange}
                    />
                    <div className="d-flex align-self-center mb-2">
                      {!selectedFile && (
                        <label
                          htmlFor="user-file-upload"
                          className="file-upload-icon"
                        >
                          <FileUploadOutlinedIcon color="primary" />
                        </label>
                      )}
                      {selectedFile && (
                        <Chip
                          color="primary"
                          sx={{ minWidth: "auto", maxWidth: "250px" }}
                          label={selectedFile.name}
                          title={selectedFile.name}
                          onDelete={handleRemoveClick}
                        />
                      )}
                    </div>
                    <div className="d-flex align-self-center mb-2">
                      <label htmlFor="user-file-upload">
                        <Link
                          variant="bodyNormal"
                          underline="none"
                          className="me-1"
                        >
                          <b>{t("opva.clickHere")}</b>
                        </Link>
                        <Typography variant="bodyNormal">
                          {t("opva.uploadDropMedia")}
                        </Typography>
                      </label>
                    </div>
                  </div>
                  <div style={{ marginLeft: "25px" }}>
                    {errorMsg && (
                      <Typography variant="body1" color="error">
                        {`Error: ${errorMsg}`}
                      </Typography>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5} className="mb-4">
                  <Button
                    size="large"
                    variant="contained"
                    sx={{ color: "#FFFFFFCC", textTransform: 'none' }}
                    onClick={handleBulkSkill}
                    disabled={!selectedFile}
                  >
                    {t("opva.confirmUpload")}
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </div>
  );
}