import { useNavigate } from "react-router-dom";
import {
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PublishIcon from "@mui/icons-material/Publish";
import CloseIcon from "@mui/icons-material/Close";
import "./VersionHistory.scss";
import {
  CollapseRowProps,
  WorkflowConstants,
  WorkflowStatusConstants,
  WorkflowVersionsModel,
} from "../../models/Workflow";
import { useAPI } from "../../../../shared/services/api/API";
import { OPTIVAL_CONSTANTS } from "../../../../shared/Constants";
import { updateURLParams } from "../../../../shared/utils/Utils";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { AlertDialog } from "../../../../shared/components/alert-dialog/AlertDialog";
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';

export const VersionHistory = (props: CollapseRowProps) => {
  const navigate = useNavigate();
  const { httpPut, handleAlertBar, httpDelete, checkRole } = useAPI();
  const { t } = useTranslation();

  const [isAlertDialog, setIsAlertDialog] = useState(false);
  const [workflowVersionId, setWorkflowVersionId] = useState<number>(0);

  const handleAlertDialogOpen = (workflowVersionId: number) => {
    setWorkflowVersionId(workflowVersionId);
    setIsAlertDialog(true);
  };

  const handleAlertDialogClose = () => {
    setWorkflowVersionId(0);
    setIsAlertDialog(false);
  };

  const handleEditWorkflow = (workflowVersionId: number) => {
    navigate(
      `/editworkflow?workflowId=${props.workflowId}&versionId=${workflowVersionId}`
    );
  };

  const handleRedirectPublishWorkflow = (workflowVersionId: number) => {
    navigate(
      `/publishworkflow?workflowId=${props.workflowId}&versionId=${workflowVersionId}`
    );
  };

  const handlePublishWorkflow = (workflowVersionId: number) => {
    const urlParams = {
      versionId: workflowVersionId || 0,
    };
    const queryParams = {
      publish: true,
    };
    httpPut(
      updateURLParams(OPTIVAL_CONSTANTS.API_URLS.PUBLISH_WORKFLOW, urlParams),
      null,
      queryParams
    ).then(
      (response) => {
        if (response && response.data && !response.data.error) {
          handleAlertBar(
            "success",
            t("opva.workflowPublishedSuccessMsg")
          );
          if (props.switchTabName) props.switchTabName();
        } else {
          handleAlertBar(
            "error",
            response?.data?.message || t("opva.serviceUnavailable")
          );
        }
      },
      (error) => {}
    );
  };

  const handleDeleteWorkflowVersion= async() => {
    const urlParams = {
      workflowId: props.workflowId, 
      versionId: workflowVersionId,
    };
    httpDelete(
      updateURLParams(OPTIVAL_CONSTANTS.API_URLS.DELETE_WORKFLOW_VERSION, urlParams),
    ).then(
      async (response) => {
        if (response?.status === 200) {
          handleAlertBar(
            "success",
            t("opva.workflowVersionDeletedSuccessMsg")
          );
          handleAlertDialogClose();
          if (props?.workflowData?.workflowVersions.length === 1) {
            props?.collapseClose();
          }
          if(props?.fetchList) {
            props?.fetchList();
          }
        } 
        else {
        handleAlertBar("error", t("opva.serviceUnavailable"));
      }
      },
      (error) => {}
    );
  };

  const handleArchiveWorkflowVersion= async(workflowVersionId: number) => {
    const urlParams = {
      workflowId: props.workflowId, 
      versionId: workflowVersionId,
    };
    const queryParams = {
      archive: props?.tabName === WorkflowConstants.ARCHIVED ? false : true
    };
    httpPut(
      updateURLParams(OPTIVAL_CONSTANTS.API_URLS.ARCHIVE_WORKFLOW_VERSION, urlParams),null, queryParams
    ).then(
      async (response) => {
        if (response?.status === 200) {
          handleAlertBar(
            "success",
            t(props?.tabName === WorkflowConstants.ARCHIVED ? "opva.workflowVersionUnarchivedSuccessMsg" : "opva.workflowVersionArchivedSuccessMsg")
          );
          if (props?.workflowData?.workflowVersions.length === 1) {
            props?.collapseClose();
          }
          if(props?.fetchList) {
            props?.fetchList();
          }
        } 
        else {
        handleAlertBar("error", t("opva.serviceUnavailable"));
      }
      },
      (error) => {}
    );
  };

  return (
    <div className="version-history-container p-4">
      <Grid container>
        <Grid item xs={6} md={6}>
          <Typography variant="h6" className="mb-3">
           {t("opva.versionHistory")}
          </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Link
            component="button"
            className="float-end"
            title="Close"
            onClick={props.collapseClose}
          >
            <CloseIcon />
          </Link>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12}>
          <TableContainer className="table-version-history-container">
            <Table
              stickyHeader
              sx={{ width: "100%", border: "1px solid rgba(221, 225, 230, 1)" }}
              size="medium"
              aria-label="Workflow version history table"
            >
              <TableHead>
                <TableRow key="workflow-table-version-history-header">
                  <TableCell
                    className="header-bg-color px-5"
                    sx={{ width: "25%" }}
                  >
                    {t("opva.workflowHistory")}
                  </TableCell>
                  <TableCell className="header-bg-color" sx={{ width: "25%" }}>
                    {t("opva.status")}
                  </TableCell>
                  <TableCell className="header-bg-color" sx={{ width: "20%" }}>
                    {t("opva.percentCompleted")}
                  </TableCell>
                  {checkRole([
                    OPTIVAL_CONSTANTS.CONTENT_ADMIN,
                    OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
                    OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
                    OPTIVAL_CONSTANTS.CONTENT_EDITOR,
                  ]) && (
                    <TableCell
                      className="header-bg-color"
                      align="center"
                      sx={{ width: "10%" }}
                    >
                      {t("opva.startResume")}
                    </TableCell>
                  )}
                  {props?.tabName !== WorkflowConstants.ARCHIVED &&checkRole([
                    OPTIVAL_CONSTANTS.CONTENT_ADMIN,
                    OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
                    OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
                  ]) && (
                    <TableCell
                      className="header-bg-color"
                      align="center"
                      sx={{ width: "10%" }}
                    >
                      {t("opva.archive")}
                    </TableCell>
                  )}
                  {props?.tabName === WorkflowConstants.ARCHIVED && checkRole([
                    OPTIVAL_CONSTANTS.CONTENT_ADMIN,
                    OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
                    OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
                  ]) && (
                    <TableCell
                      className="header-bg-color"
                      align="center"
                      sx={{ width: "10%" }}
                    >
                      {t("opva.unarchive")}
                    </TableCell>
                  )}
                  {checkRole([
                    OPTIVAL_CONSTANTS.CONTENT_ADMIN,
                    OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
                    OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
                  ]) && (
                    <TableCell
                      className="header-bg-color"
                      align="center"
                      sx={{ width: "10%" }}
                    >
                      {t("opva.publish")}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.workflowData?.workflowVersions &&
                (props.workflowData?.workflowVersions).length ? (
                  props.workflowData.workflowVersions.map(
                    (data: WorkflowVersionsModel, index: number) => {
                      return (
                        <TableRow key={index}>
                          <TableCell className="px-5">
                            {t("opva.version")} {data.versionNumber}
                          </TableCell>
                          <TableCell>
                            {data.status === WorkflowConstants.IN_REVIEW && (
                              <>{WorkflowStatusConstants.IN_REVIEW}</>
                            )}
                            {data.status ===
                              WorkflowConstants.WORK_IN_PROGRESS && (
                              <>{WorkflowStatusConstants.WORK_IN_PROGRESS}</>
                            )}
                            {data.status === WorkflowConstants.DRAFT && (
                              <>{WorkflowStatusConstants.DRAFT}</>
                            )} {data.status === WorkflowConstants.ARCHIVE && (
                              <>{WorkflowStatusConstants.ARCHIVED}</>
                            )}
                          </TableCell>
                          <TableCell>0%</TableCell>
                          {checkRole([
                            OPTIVAL_CONSTANTS.CONTENT_ADMIN,
                            OPTIVAL_CONSTANTS.CONTENT_EDITOR,
                            OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
                            OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
                          ]) && (
                            <TableCell align="center">
                              <Link
                                component="button"
                                title="Edit"
                                disabled={
                                  data.status === WorkflowConstants.IN_REVIEW &&
                                  checkRole([OPTIVAL_CONSTANTS.CONTENT_EDITOR])
                                }
                                onClick={(e) => handleEditWorkflow(data.id)}
                              >
                                <EditIcon
                                  color={
                                    data.status ===
                                      WorkflowConstants.IN_REVIEW &&
                                    checkRole([
                                      OPTIVAL_CONSTANTS.CONTENT_EDITOR,
                                    ])
                                      ? "disabled"
                                      : "primary"
                                  }
                                />
                              </Link>
                            </TableCell>
                          )}
                          {checkRole([
                            OPTIVAL_CONSTANTS.CONTENT_ADMIN,
                            OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
                            OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
                          ]) && (
                            <TableCell align="center">
                              <Link component="button" title={t(props?.tabName === WorkflowConstants.ARCHIVED ? "opva.unarchive" : "opva.archive")}
                                  onClick={() => handleArchiveWorkflowVersion(data.id)}>
                                {props?.tabName === WorkflowConstants.ARCHIVED ? <UnarchiveOutlinedIcon color="primary" /> : <ArchiveOutlinedIcon color="primary"/>}
                              </Link>
                            </TableCell>
                          )}
                          {checkRole([
                            OPTIVAL_CONSTANTS.CONTENT_ADMIN,
                            OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
                            OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
                          ]) && (
                            <TableCell className="p-2" align="center">
                              <Link
                                component="button"
                                title={t("opva.publish")}
                                onClick={(e) =>
                                  handleRedirectPublishWorkflow(data.id)
                                }
                              >
                                <PublishIcon />
                              </Link>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    }
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      {t("opva.noWorkflowHistoryAvailable")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <AlertDialog
          open={isAlertDialog}
          title={t("opva.deleteWorkflowVersion")}
          description={t("opva.confirmationMsgWorkflowVersion")}
          submitBtnText={t("opva.delete")}
          handleSubmit={handleDeleteWorkflowVersion}
          handleClose={handleAlertDialogClose}
        ></AlertDialog>
    </div>
  );
};
