import { createContext, useEffect, useMemo, useState } from "react";
import { NotificationContextModel } from "../models/NotificationContextModel";
import { useTranslation } from "react-i18next";
import { useAPI } from "../../../shared/services/api/API";
import { OPTIVAL_CONSTANTS } from "../../../shared/Constants";

export const NotificationContext = createContext(
  {} as NotificationContextModel
);

export const NotificationProvider = ({ children }: any) => {
  const [haveUnreadNotifications, setHaveUnreadNotifications] = useState<boolean>(false);
  const { httpGet, handleAlertBar } = useAPI();
  const { t } = useTranslation();

  const updateHaveUnreadNotifications = (value: boolean) => {
    setHaveUnreadNotifications(value);
  };

  const notificationProviderValues = useMemo(
    () => ({ 
      haveUnreadNotifications , 
      updateHaveUnreadNotifications,
     }),
    [
      haveUnreadNotifications, 
    ]
  );

  return (
    <NotificationContext.Provider value={notificationProviderValues}>
      {children}
    </NotificationContext.Provider>
  );
};
