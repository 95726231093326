import { Autocomplete, Box, Button, Chip, debounce, Dialog, DialogActions, DialogContent, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useAPI } from '../../../../../shared/services/api/API';
import { OPTIVAL_CONSTANTS } from '../../../../../shared/Constants';

export const SelectSkillDialog = (Props : any) => {
    const { t } = useTranslation();
    const { httpPost, handleAlertBar } = useAPI();
    const [skillsList, setSkillsList] = useState<any>([]);
    const [isSkillSearching, setIsSkillSearching] = useState(false);

    const handleSelectSkills = (skillsDetail: any) => {
      if(Props?.byUser) {
        const selectedSkillsIndex = Props?.selectUserSkillsList.findIndex(
          (skill: any) => skill.skillId === skillsDetail.skillId
        );
        if (selectedSkillsIndex === -1) {
          const list = [...Props?.selectUserSkillsList, skillsDetail];
          Props?.setSelectUserSkillsList(list);
        }
      } else {
        const selectedSkillsIndex = Props?.selectSkillsList.findIndex(
          (skill: any) => skill.skillId === skillsDetail.skillId
        );
        if (selectedSkillsIndex === -1) {
          const list = [...Props?.selectSkillsList, skillsDetail];
          Props?.setSelectSkillsList(list);
        }
      }
    };

    const handleRemoveSkill = async (skillId: number) => {
        const removeSelectSkillsList = Props?.selectSkillsList?.filter(
          (items: any) => items.skillId !== skillId
        );
        Props?.setSelectSkillsList(removeSelectSkillsList);
    };

    const handleRemoveUserSkill = async (skillId: number) => {
      const removeSelectSkillsList = Props?.selectUserSkillsList?.filter(
        (items: any) => items.skillId !== skillId
      );
      Props?.setSelectUserSkillsList(removeSelectSkillsList);
  };

    const fetchSkillsList = useMemo(
        () =>
          debounce((searchUser: string) => {
            setIsSkillSearching(true);
            const alreadyUserSelectedSkillId = Props?.selectUserSkillsList?.map((e: any) => e?.skillId);
            const alreadySelectedSkillId = Props?.selectSkillsList?.map((e: any) => e?.skillId);
            const reqBody = {
              keyword: searchUser,
              skillIdsToExclude: Props?.byUser ? alreadyUserSelectedSkillId : alreadySelectedSkillId
            };
            httpPost(OPTIVAL_CONSTANTS.API_URLS.SEARCH_SKILLS, reqBody).then(
              (response: any) => {
                setIsSkillSearching(false);
                if (response && response.data && !response.data?.error) {
                  setSkillsList(response.data);
                } else {
                  handleAlertBar(
                    "error",
                    response?.data?.message || t("opva.serviceUnavailable")
                  );
                }
              },
              (error: any) => {
                setIsSkillSearching(false);
                handleAlertBar(
                  "error",
                  error.message || t("opva.serviceUnavailable")
                );
              }
            );
          }, 500),
        [Props?.selectSkillsList, Props?.selectUserSkillsList, Props?.byUser]
      );

      useEffect(() => {
        if(Props?.openDialog) {
          fetchSkillsList('');
        }
      },[Props?.selectUserSkillsList, Props?.openDialog])
    return (
      <Dialog fullWidth open={Props?.openDialog} onClose={Props?.handleCloseDialog}>
        <DialogContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant='h5'>{t("opva.selectSkill")}</Typography>
            <IconButton aria-label="close" onClick={Props?.handleCloseDialog} color='primary'>
              <CloseIcon />
            </IconButton>
          </div>
          <Box>
            <div style={{ display: "flex", flexDirection: "column" }} className='mt-4'>
            <Typography variant='bodySmall'>{t("opva.search")}</Typography>
          <Autocomplete
            id="search-by-skill"
            className="w-100"
            options={skillsList ? skillsList : []}
            autoComplete
            loading={isSkillSearching}
            loadingText={"Skills Searching"}
            noOptionsText={
              !skillsList ? (
                <Typography variant="labelNormal">
                  {t("opva.type2Letters")}
                </Typography>
              ) : (
                <Typography variant="labelNormal">
                  {t("opva.noSkillsFound")}
                </Typography>
              )
            }
            getOptionLabel={(option: any | string) => Props?.searchSkillName}
            renderOption={(props, option: any) => (
              <li {...props} key={option.id} value={option.id}>
                <Typography variant="labelLarge">{option.name}</Typography>
              </li>
            )}
            value={Props?.searchSkillName}
            onChange={(event: any, value: any | null) => {
              if (value && typeof value === "object") {
                handleSelectSkills(value);
              }
            }}
            onInputChange={(event: any, newInputValue: string) => {
              Props?.setSearchSkillName(newInputValue);
              // if (newInputValue && newInputValue.length > 2) {
                fetchSkillsList(newInputValue);
              // }
            }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                size="small"
                variant="standard"
                placeholder={t("opva.searchBySkill")}
                className="search-name-text"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="inherit" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <></>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>
        <div className="mt-1">
          {Props?.byUser ? <>
            {Props?.selectUserSkillsList?.map((skill: any, index: any) => (
            <Chip
              color="default"
              size="medium"
              variant="filled"
              className="me-2 mt-2"
              key={index}
              label={skill?.name}
              onDelete={() => handleRemoveUserSkill(skill.skillId)}
            />
          ))}
          </> : (
            <>{Props?.selectSkillsList?.map((skill: any, index: any) => (
              <Chip
                color="default"
                size="medium"
                variant="filled"
                className="me-2 mt-2"
                key={index}
                label={skill?.name}
                onDelete={() => handleRemoveSkill(skill.skillId)}
              />
            ))}</>
          )}
        </div>
      </Box>
        </DialogContent>
        <DialogActions className="dialog-btn">
          <Button
            onClick={() => Props?.selectSkills()}
            color="primary"
            variant="contained"
          >
            {t("opva.selectSkill")}
          </Button>
        </DialogActions>
      </Dialog>
    );
}