import { useTranslation } from "react-i18next";
import { TableHeadCell } from "../../../shared/models/TableModels";
import { t } from "i18next";

export const SkillsConstants = {
  NO_DATA_AVAILABLE: "No data available.",
  NO_SEARCH_DATA_AVAILABLE: "Search skillz data is not available.",
  SKILL_CREATE_MESG: "Skill data added successfully",
  SKILL_DELETE_MESG: "Skill data removed successfully.",
  SKILL_UPDATE_MESG: "Skill data updated successfully",
  ASSIGN_SKILL_TO_EMPLOYEE: "Skill assigned to the employee successfully",
  REMOVE_SKILLS_FROM_EMPLOYEE_MESG: "Skill removed from employee successfully",
  MAP_SKILLS_FROM_WORKFLOWS_MESG: "Skill workflow mapped successfully",
  UNMAP_SKILLS_FROM_WORKFLOWS_MESG: "Skill workflow unmapped successfully",
  SKILL_DESCRIPTION_LENGTH: 120,
  VIEW_MORE_TEXT: "view more",
  VIEW_LESS_TEXT: "view less",
  NO_SEARCH_DATA_AVAILABLE_FOR_MAP:
    "Search mapped workflow data is not available.",
  NO_SEARCH_DATA_AVAILABLE_FOR_UNMAP:
    "Search unmapped workflow data is not available.",
  NO_SEARCH_DATA_AVAILABLE_FOR_ASSIGN: "Search assigned data is not available.",
  NO_SEARCH_DATA_AVAILABLE_FOR_UNASSIGNED:
    "Search unassigned data is not available.",
  MAP_WORK_FLOW: "Map Workflow",
  ASSIGN_SKILLZ: "Assign",
  SKILLS_NAME_LENGTH: 35,
  ASSIGN_BULK_USER: "Assign to bulk users",
  ASSIGN_BULK_USER_DOCUMENT_FORMAT:
    "To assign this skill to bulk users, download our format and fill and upload the excel.",
  CLICK_HERE: "Click here",
  UPLOAD_EXCEL_FILE: "to upload or drop excel file",
  DOWNLOAD_FORMAT_HERE: "You can download the format here",
  ADD_BULK_USER: "Add Bulk Users",
  BULK_ASSIGN_SKILL_MESG: "Skill has been assigned successfully",

  // MAP_WORKFLOW_HEADER: [
  //   {
  //     id: "workflow",
  //     label: t("opva.workflow"),
  //     sorting: true,
  //     sortingProperty: "name",
  //     width: 35,
  //   },
  //   {
  //     id: "createdBy",
  //     label: t("opva.createdBy"),
  //     sorting: true,
  //     sortingProperty: "createdBy",
  //     width: 35,
  //   },
  //   {
  //     id: "assignedTo",
  //     label: t("opva.assignedTo"),
  //     sorting: false,
  //     sortingProperty: "",
  //     width: 35,
  //   },
  // ] as TableHeadCell[],

  // ASSIGN_SKILLZ_HEADER: [
  //   {
  //     id: "name",
  //     label: t("opva.name"),
  //     sorting: true,
  //     sortingProperty: "firstName",
  //     width: 15,
  //     position: "left",
  //   },
  //   {
  //     id: "employeeId",
  //     label: t("opva.employeeId"),
  //     sorting: true,
  //     sortingProperty: "employeeId",
  //     width: 20,
  //     position: "left",
  //   },
  //   {
  //     id: "email",
  //     label: t("opva.email"),
  //     sorting: true,
  //     sortingProperty: "mail",
  //     width: 20,
  //     position: "left",
  //   },
  //   {
  //     id: "role",
  //     label: t("opva.role"),
  //     sorting: true,
  //     sortingProperty: "assignedRoles",
  //     width: 20,
  //     position: "left",
  //   },
  //   {
  //     id: "status",
  //     label: t("opva.status"),
  //     sorting: false,
  //     sortingProperty: "",
  //     width: 15,
  //     position: "left",
  //   },
  //   {
  //     id: "action",
  //     label: t("opva.Actions"),
  //     sorting: false,
  //     sortingProperty: "",
  //     width: 15,
  //     position: "center",
  //   },
  // ] as TableHeadCell[],
};

export const useMapAndAssignUtils = () => {
  const { t } = useTranslation();
  const getMapWorkFlowHeaders = () => {
    return [
      {
        id: "workflow",
        label: t("opva.workflow"),
        sorting: true,
        sortingProperty: "name",
        width: 35,
      },
      {
        id: "createdBy",
        label: t("opva.createdBy"),
        sorting: true,
        sortingProperty: "createdBy",
        width: 35,
      },
      {
        id: "assignedTo",
        label: t("opva.assignedTo"),
        sorting: false,
        sortingProperty: "",
        width: 35,
      },
    ] as TableHeadCell[];
  };
  const getAssignWorkflowHeaders = () => {
    return [
      {
        id: "name",
        label: t("opva.name"),
        sorting: true,
        sortingProperty: "firstName",
        width: 15,
        position: "left",
      },
      {
        id: "employeeId",
        label: t("opva.employeeId"),
        sorting: true,
        sortingProperty: "employeeId",
        width: 20,
        position: "left",
      },
      {
        id: "email",
        label: t("opva.email"),
        sorting: true,
        sortingProperty: "mail",
        width: 20,
        position: "left",
      },
      {
        id: "role",
        label: t("opva.role"),
        sorting: true,
        sortingProperty: "assignedRoles",
        width: 20,
        position: "left",
      },
      {
        id: "validationStatus",
        label: t("opva.validationStatus"),
        sorting: false,
        sortingProperty: "",
        width: 15,
        position: "left",
      },
      {
        id: "skillLevelName",
        label: t("opva.skillLevel"),
        sorting: false,
        sortingProperty: "",
        width: 15,
        position: "left",
      },
      {
        id: "action",
        label: t("opva.actions"),
        sorting: false,
        sortingProperty: "",
        width: 15,
        position: "center",
      },
    ] as TableHeadCell[];
  };
  return { getMapWorkFlowHeaders, getAssignWorkflowHeaders };
};
