import {
  ReactElement,
  Ref,
  SyntheticEvent,
  forwardRef,
  useContext,
  useEffect,
  useState,
  DragEvent,
} from "react";
import {
  Box,
  Typography,
  Step,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Link,
  Slide,
  IconButton,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CloseIcon from "@mui/icons-material/Close";
import "./NewWorkflowCreation.scss";
import { useAPI } from "../../../shared/services/api/API";
import { OPTIVAL_CONSTANTS } from "../../../shared/Constants";
import { useLocation, useNavigate } from "react-router-dom";
import { updateURLParams } from "../../../shared/utils/Utils";
import { WorkflowConstants } from "../../workflow/models/Workflow";
import { IntroWorkflow } from "./create-workflow/intro-workflow/IntroWorkflow";
import { ReviewWorkflow } from "./create-workflow/review-workflow/ReviewWorkflow";
import { StepWorkflow } from "./create-workflow/step-workflow/StepWorkflow";
import { CREATE_WF_CONSTANTS } from "../models/CreateWorkflowConstant";
import { AuthenticationContext } from "../../../shared/Contexts";
import { NewWorkflowConstants } from "../models/WorkflowData";
import { AlertDialog } from "../../../shared/components/alert-dialog/AlertDialog";
import { AssignWorkflow } from "./assign-workflow/AssignWorkflow";
import { Breadcrumb } from "../../breadcrumb/components/Breadcrumb";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useTranslation } from "react-i18next";

const DialogTransition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return (
    <Slide direction="left" mountOnEnter unmountOnExit ref={ref} {...props} />
  );
});

export const NewWorkflowCreation = (Props: any) => {
  const { t } = useTranslation();
  const { userRole } = useContext(AuthenticationContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [workflowInitialStepDetail, setInitialWorkflowStepDetail] =
    useState<any>({});
  const [editStepDetail, seteditStepDetail] = useState<any>({});
  const [deleteStepDetail, setdeleteStepDetail] = useState<any[]>([]);
  const [workflowStepDetail, setWorkflowStepDetail] = useState<any>({});
  const [stepWorkflowStepDetail, setStepWorkflowStepDetail] = useState<any>({});
  const { httpPost, httpGet, httpPut, httpDelete, checkRole, handleAlertBar } =
    useAPI();
  const navigate = useNavigate();
  const [assignUserIds, setAssignUserIds] = useState<number[] | null>(null);
  const [uploadedData, setUploadedData] = useState<any[]>([]);
  const [stepDetail, setStepDetail] = useState<any>({});
  const [initialUploadedData, setInitialUploadedData] = useState<any[]>([]);
  const [versionData, setVersionData] = useState<any>({});
  const [tagList, setTagList] = useState([]);
  const [workflowData, setWorkflowData] = useState<any>({});
  const [activeStep, setActiveStep] = useState(0);
  const [tag, setTag] = useState("");
  const [tagName, setTagName] = useState("");
  const [steps, setSteps] = useState([
    "opva.workflowDetails",
    "opva.steps",
    "opva.review",
  ]);
  const [tabValue, setTabValue] = useState(
    CREATE_WF_CONSTANTS.ENGLISH.toUpperCase()
  );
  const [tabList, setTabList] = useState([]);
  const [isEditWorkflowOpen, setIsEditWorkflowOpen] = useState(false);
  const [editContentDetail, setEditContentDetail] = useState("");
  const [stepsIndex, setStepIndex] = useState<number | undefined>(0);
  const [isSaveDisable, setIsSaveDisable] = useState(false);
  const [isnextDisable, setIsNextDisable] = useState(false);
  const [isPublishBtnDisable, setIsPublishBtnDisable] = useState(false);
  const [isPublishWorkflow, setIsPublishWorkflow] = useState(false);
  const [audioObj, setAudioObj] = useState<any>();
  const [isAlertDialog, setIsAlertDialog] = useState(false);
  const [isUnsavedChangesAlertDialog, setIsUnsavedChangesAlertDialog] = useState(false);
  const [deleteStepIndex, setdeleteStepIndex] = useState<number>(-1);
  const [isWorkflowSubmitDialog, setIsWorkflowSubmitDialog] = useState(false);
  const [draggingItem, setDraggingItem] = useState<any>({});

  const handleDragStart = (e: DragEvent<HTMLDivElement>, item: any) => {
    setDraggingItem(item);
    e.dataTransfer.setData("text/plain", "");
  };

  const handleDragEnd = () => {
    setDraggingItem({});
  };

  const handleDragOver = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>, targetItem: any) => {
    if (!draggingItem) return;

    const currentIndex = uploadedData.indexOf(draggingItem);
    const targetIndex = uploadedData.indexOf(targetItem);

    if (currentIndex !== -1 && targetIndex !== -1) {
      uploadedData.splice(currentIndex, 1);
      uploadedData.splice(targetIndex, 0, draggingItem);
      const updatedData = [...uploadedData];
      let obj: any = {};
      updatedData.forEach((item, i) => {
        const additionalPropKey = item?.workflowStepDto?.id
          ? item?.workflowStepDto?.id
          : item?.id;
        obj[additionalPropKey] = i + 1;
      });
      const request = {
        order: obj,
      };
      reorderSteps(request);
      // setInitialUploadedData(uploadedData);
      // setUploadedData(uploadedData);
    }
  };

  const handleAlertDialogOpen = (step: number) => {
    setdeleteStepIndex(step);
    setIsAlertDialog(true);
  };

  const handleAlertDialogClose = () => {
    setdeleteStepIndex(-1);
    setIsAlertDialog(false);
  };

  const handleUnsavedChangesAlertDialogOpen = () => {
    if(activeStep === 1 && stepDetail?.title && stepDetail?.description) {
      setIsUnsavedChangesAlertDialog(true);
    } else {
      previousButton();
    }
  };

  const handleSavedChanges = () => {
    handleSaveClick();
  }

  const handleSaveClick = async () => {
    if (audioObj) {
      audioObj.pause();
    }
    const formData = new FormData();
    formData.append("stepTitle", stepDetail?.title);
    formData.append("stepDescription", stepDetail?.description);
    formData.append("optional", `${stepDetail?.isChecked}`);
    let deleteMedia = true;
    if (stepDetail?.selectedFile) {
        let mb =  500;
        console.log(process.env.REACT_APP_OPVA_ENV_SUFFIX)
        if(process.env.REACT_APP_OPVA_ENV_SUFFIX=== "DEV") {
          mb = 10;
        }
        const MAX_VIDEO_SIZE = mb * 1024 * 1024;
        if (stepDetail?.selectedFile.size >= MAX_VIDEO_SIZE) {
          handleAlertBar("error", `The file size should be less than ${mb}MB`);
        }
        formData.append("multipartFile", stepDetail?.selectedFile);
    }
    await httpPost(
        `${OPTIVAL_CONSTANTS.API_URLS.CREATE_WORKFLOW_STEP}/${workflowData?.id}/${versionData?.id}`,
        formData
    ).then(
        (response) => {
          if (response && response.data && response.data.error) {
            handleAlertBar("error", response.data.message);
          } else {
            console.log("step response-->", response);
            setUploadedData((prevData) => [...prevData, response.data]);
            handleUnsavedChangesAlertDialogClose();
          }
        },
        (error) => {
          // handleAlertBar(
          //   "error",
          //   error.message || t("opva.serviceUnavailable")
          // );
        }
    );
  };

  const handleUnsavedChangesAlertDialogClose = () => {
    setIsUnsavedChangesAlertDialog(false);
    previousButton();
  };
  const handleEditWorkflowModelOpen = (
    editContent: string,
    stepsIndex?: number
  ) => {
    setEditContentDetail(editContent);
    if (stepsIndex || stepsIndex === 0)
      seteditStepDetail(uploadedData[stepsIndex]);
    setStepIndex(stepsIndex);
    setIsEditWorkflowOpen(true);
  };

  const updateButton = () => {
    if (audioObj) {
      audioObj.pause();
    }
    if (editContentDetail === "Workflow") {
      createNewWorkflow(tabValue);
    } else if (editContentDetail === "Steps") {
      updateStep(tabValue);
    }
  };
  const updateStep = async (language: string) => {
    const formData = new FormData();
    formData.append("stepTitle", stepWorkflowStepDetail?.title);
    formData.append("stepDescription", stepWorkflowStepDetail?.description);
    formData.append("optional", `${stepWorkflowStepDetail?.optional}`);
    let deleteMedia = true;
    if (editStepDetail?.workflowStepDto?.id || editStepDetail?.id) {
      if (stepWorkflowStepDetail?.mediaItems[0]) {
        let mb =  500;
        if(process.env.REACT_APP_OPVA_ENV_SUFFIX=== "DEV") {
          mb = 10;
        }
        const MAX_VIDEO_SIZE = mb * 1024 * 1024;
        if (stepWorkflowStepDetail?.mediaItems[0].size >= MAX_VIDEO_SIZE) {
          handleAlertBar("error", `The file size should be less than ${mb}MB`);
        }
        if (stepWorkflowStepDetail?.mediaItems[0]?.url) {
          deleteMedia = false;
          formData.append(
            "multipartFile",
            new Blob([JSON.stringify(stepWorkflowStepDetail?.mediaItems[0])])
          );
        } else {
          deleteMedia = true;
          formData.append(
            "multipartFile",
            stepWorkflowStepDetail?.mediaItems[0]
          );
        }
      }
      const urlParams = {
        workflowId: workflowData?.id || 0,
        versionId: versionData?.id || 0,
        stepId: editStepDetail?.id || 0,
      };
      const queryParams = {
        deleteMedia: deleteMedia,
        language: language,
      };
      await httpPut(
        updateURLParams(OPTIVAL_CONSTANTS.API_URLS.UPDATE_STEP, urlParams),
        formData,
        queryParams
      ).then(
        (response) => {
          if (response && response.data && response.data.error) {
            handleAlertBar("error", response.data.message);
          } else {
            console.log("edit step response-->", response.data);
            setUploadedData((prevData) => {
              const existingIndex = prevData.findIndex(
                (item) =>
                  (item?.workflowStepDto?.id || item?.id) ===
                  response?.data?.workflowStepDto?.id
              );
              if (existingIndex !== -1) {
                const newData = [...prevData];
                newData[existingIndex] = response.data?.workflowStepDto
                  ? response.data?.workflowStepDto
                  : response.data;
                return newData;
              } else {
                return [...prevData, response.data];
              }
            });
            handleEditWorkflowModelClose(null, "close");
            handleAlertBar("success", t("opva.stepUpdatedMsg"));
          }
        },
        (error) => {
        }
      );
    }
  };

  const handleEditWorkflowModelClose = (event: any, reason: string) => {
    if (reason && reason === "backdropClick") return;
    setIsEditWorkflowOpen(false);
    if (audioObj) {
      audioObj.pause();
    }
  };

  const workflowStepData = (data: any) => {
    setWorkflowStepDetail(data);
    if (!data.workflowTitle || !data.workflowDescription) {
      setIsSaveDisable(true);
      setIsNextDisable(true);
    } else {
      setIsSaveDisable(false);
      setIsNextDisable(false);
    }
  };
  const StepTwoData = (data: any) => {
    setStepDetail(data);
    setUploadedData(data?.uploadedData);
    if (data?.uploadedData.length > 0) {
      setIsNextDisable(false);
      setIsPublishBtnDisable(false);
    } else {
      setIsNextDisable(true);
      setIsPublishBtnDisable(true);
    }
    if ((stepsIndex || stepsIndex === 0) && editContentDetail === "Steps") {
      const obj = {
        title: data.title,
        description: data.description,
        mediaItems: [data.selectedFile],
        optional: data.isChecked,
      };
      setStepWorkflowStepDetail(obj);
      if (!obj?.title || !obj?.description) {
        setIsSaveDisable(true);
      } else {
        setIsSaveDisable(false);
      }
    }
  };
  const getOrganizationSupportLanguage = async () => {
    await httpGet(
      OPTIVAL_CONSTANTS.API_URLS.ORGANIZATION_LANGUAGE,
      {},
      false
    ).then(
      (response) => {
        if (response && response.data && response.data.error) {
          handleAlertBar("error", response.data.message);
        } else {
          console.log(
            "organization language support response-->",
            response.data
          );
          setTabList(response.data);
        }
      },
      (error) => {}
    );
  };
  const createNewWorkflow = async (language?: string) => {
    const formData = new FormData();
    formData.append("name", workflowStepDetail?.workflowTitle);
    formData.append("description", workflowStepDetail?.workflowDescription);
    formData.append("tagIds", workflowStepDetail?.tag);
    let deleteMedia = false;
    if (workflowStepDetail?.workflowSelectedFile) {
      if (workflowStepDetail?.workflowSelectedFile?.type) {
        deleteMedia = true;
      }
      formData.append("file", workflowStepDetail?.workflowSelectedFile);
    }
    if (workflowData?.id) {
      const urlParams = {
        workflowId: workflowData?.id || 0,
      };
      let queryParams = {};
      if (
        editContentDetail === "Workflow" &&
        tabValue !== CREATE_WF_CONSTANTS.ENGLISH.toUpperCase()
      ) {
        queryParams = {
          deleteMedia: deleteMedia,
          language: language,
        };
      } else {
        queryParams = {
          deleteMedia: deleteMedia,
        };
      }
      await httpPut(
        updateURLParams(OPTIVAL_CONSTANTS.API_URLS.UPDATE_WORKFLOW, urlParams),
        formData,
        queryParams
      ).then(
        (response) => {
          if (response && response.data && response.data.error) {
            handleAlertBar("error", response.data.message);
          } else {
            console.log(
              "update workflow with version response-->",
              response.data
            );
            setWorkflowData(response.data);
            const workflowVersion = response.data;
            const EditWorkflowDetail = {
              workflowTitle: workflowVersion?.name,
              workflowDescription: workflowVersion?.description,
              workflowSelectedFile: workflowVersion?.url,
              tag:
                workflowVersion?.tags && workflowVersion.tags.length
                  ? workflowVersion.tags[0].id
                  : 0,
              workflowtranslatedLanguages: workflowVersion?.translatedLanguages,
              translatedNames: workflowVersion?.translatedNames,
              translatedDescriptions: workflowVersion?.translatedDescriptions,
            };
            setTagName(
              workflowVersion?.tags && workflowVersion.tags.length
                ? workflowVersion.tags[0].name
                : ""
            );
            setInitialWorkflowStepDetail(EditWorkflowDetail);
            if (editContentDetail === "Workflow" || editContentDetail !== "") {
              handleAlertBar("success", t("opva.workflowUpdatedMsg"));
              handleEditWorkflowModelClose(null, "close");
            } else {
              setActiveStep((prevStep) => prevStep + 1);
            }
          }
        },
        (error) => {}
      );
    } else {
      let queryParams = {
        contentEditorId: assignUserIds ? assignUserIds.join(",") : null,
      };
      await httpPost(
        OPTIVAL_CONSTANTS.API_URLS.CREATE_WORKFLOW_WITH_VERSION,
        formData,
        queryParams
      ).then(
        (response) => {
          if (response && response.data && response.data.error) {
            handleAlertBar("error", response.data.message);
          } else {
            console.log(
              "create workflow with version response-->",
              response.data
            );
            setTag(
              response?.data?.workflowDto?.tags &&
                response?.data?.workflowDto.tags.length
                ? response?.data?.workflowDto.tags[0].id
                : 0
            );
            setTagName(
              response?.data?.workflowDto?.tags &&
                response?.data?.workflowDto?.tags.length
                ? response?.data?.workflowDto?.tags[0].name
                : ""
            );
            setWorkflowData(response?.data?.workflowDto);
            setVersionData(response?.data?.workflowVersionDto);
            setActiveStep((prevStep) => prevStep + 1);
          }
        },
        (error) => {}
      );
    }
  };
  const handleTabChange = async (event: SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };
  const translateLanguage = async () => {
    const urlParams = {
      workflowId: workflowData?.id || 0,
      versionId: versionData?.id || 0,
    };
    await httpGet(
      updateURLParams(OPTIVAL_CONSTANTS.API_URLS.VERSION_WORKFLOW, urlParams)
    ).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response.data.message || t("opva.serviceUnavailable")
            );
          } else {
            console.log("Translate data ---->", response.data);
            const workflowTranslated = response.data?.workflow;
            setVersionData(response?.data);
            if (workflowTranslated && Object.keys(workflowTranslated).length) {
              setWorkflowData(workflowTranslated);
              const EditWorkflowDetail = {
                workflowTitle: workflowTranslated?.name,
                workflowDescription: workflowTranslated?.description,
                workflowSelectedFile: workflowTranslated?.url,
                tag:
                  workflowTranslated?.tags && workflowTranslated.tags.length
                    ? workflowTranslated.tags[0].id
                    : 0,
                workflowtranslatedLanguages:
                  workflowTranslated?.translatedLanguages,
                translatedNames: workflowTranslated?.translatedNames,
                translatedDescriptions:
                  workflowTranslated?.translatedDescriptions,
              };
              setInitialWorkflowStepDetail(EditWorkflowDetail);
            }
            const data = response.data?.steps.sort(
              (a: any, b: any) => a.order - b.order
            );
            setUploadedData(data);
            setInitialUploadedData(data);
            setActiveStep((prevStep) => prevStep + 1);
          }
        }
      },
      (error) => {}
    );
  };

  const getTag = async () => {
    await httpGet(OPTIVAL_CONSTANTS.API_URLS.TAG, {}, false).then(
      (response) => {
        if (response && response.data && response.data.error) {
          handleAlertBar("error", response.data.message);
        } else {
          console.log("tag response-->", response.data);
          setTagList(response.data);
        }
      },
      (error) => {}
    );
  };
  const getAudioPlayURL = (text: string) => {
    const queryParams = {
      languageCode: tabValue,
    };
    const requestBody = {
      textToTranslate: text,
    };
    httpPost(
      OPTIVAL_CONSTANTS.API_URLS.TEXT_TO_SPEECH,
      requestBody,
      queryParams
    ).then(
      (response) => {
        if (response && response.data) {
          if (response.data?.error) {
            handleAlertBar(
              "error",
              response?.data?.message || t("opva.serviceUnavailable")
            );
          } else {
            if (response.data?.url) {
              if (audioObj) {
                audioObj.pause();
              }
              const newAudioObj = new Audio(response.data?.url);
              setAudioObj(newAudioObj);
              newAudioObj.play();
            } else {
              handleAlertBar("warning", "Audio content url is not found!!!");
            }
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  };
  const editWorkflow = async () => {
    const urlParams = {
      workflowId: queryParams.get("workflowId"),
      versionId: queryParams.get("versionId"),
    };
    await httpGet(
      updateURLParams(OPTIVAL_CONSTANTS.API_URLS.VERSION_WORKFLOW, urlParams)
    ).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar("error", response.data.message);
          } else {
            console.log("edit workflow response-->", response.data);
            const workflowVersion = response.data;
            const EditWorkflowDetail = {
              workflowTitle: workflowVersion?.workflow?.name,
              workflowDescription: workflowVersion?.workflow?.description,
              workflowSelectedFile: workflowVersion?.workflow?.url,
              translatedNames: workflowVersion?.workflow?.translatedNames,
              translatedDescriptions:
                workflowVersion?.workflow?.translatedDescriptions,
              tag:
                workflowVersion?.workflow.tags &&
                workflowVersion.workflow.tags.length
                  ? workflowVersion.workflow.tags[0].id
                  : 0,
              workflowtranslatedLanguages:
                workflowVersion?.workflow?.translatedLanguages,
            };
            workflowVersion?.steps?.map(
              (item: any) => (item["workflowStepDto"] = item)
            );
            setTagName(
              workflowVersion?.workflow.tags &&
                workflowVersion.workflow.tags.length
                ? workflowVersion.workflow.tags[0].name
                : ""
            );
            setInitialWorkflowStepDetail(EditWorkflowDetail);
            setWorkflowData(workflowVersion?.workflow);
            setVersionData(workflowVersion);
            setTag(EditWorkflowDetail.tag);
            const data = workflowVersion?.steps.sort(
              (a: any, b: any) => a.order - b.order
            );
            setUploadedData(data);
            setInitialUploadedData(data);
            if (Props?.isPublish) {
              setActiveStep(2);
            }
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  };
  // Call fetchData on component mount
  useEffect(() => {
    getTag();
    getOrganizationSupportLanguage();
    if (Props?.isEdit || Props?.isPublish) {
      editWorkflow();
    }
  }, []);
  const backToWorkflow = () => {
    navigate("/instrukt");
  };

  const deleteIconClick = async () => {
    const modifyStepData = uploadedData.map((data) =>
      data.workflowStepDto ? data["workflowStepDto"] : data
    );
    const updatedData = [...modifyStepData];
    await httpDelete(
      `${OPTIVAL_CONSTANTS.API_URLS.DELETE_STEP}/${workflowData?.id}/${versionData?.id}/${updatedData[deleteStepIndex].id}`
    ).then(
      (response) => {
        if (response && response.data && response.data.error) {
          handleAlertBar("error", response.data.message);
        } else {
          console.log("delete response-->", response.data);
          updatedData.splice(deleteStepIndex, 1);
          setUploadedData(updatedData);
          setInitialUploadedData(updatedData);
          seteditStepDetail({});
          setdeleteStepDetail(updatedData);
          if (updatedData.length > 0) {
            setIsPublishBtnDisable(false);
          } else {
            setIsPublishBtnDisable(true);
          }
          handleAlertDialogClose();
          handleAlertBar("success", t("opva.stepDeletedMsg"));
        }
      },
      (error) => {}
    );
  };

  const editIconClick = (index: number) => {
    if (index || index === 0) {
      if (uploadedData[index]?.mediaItemDto) {
        uploadedData[index].workflowStepDto.mediaItems = [
          uploadedData[index]?.mediaItemDto,
        ];
      }
      seteditStepDetail(
        uploadedData[index].workflowStepDto
          ? uploadedData[index].workflowStepDto
          : uploadedData[index]
      );
    }
  };

  const reorderIconClick = async (index: number) => {
    const selectedItem = uploadedData[index];
    if (index !== 0) {
      uploadedData.splice(index, 1);
      uploadedData.splice(0, 0, selectedItem);
    } else {
      uploadedData.splice(index, 1);
      uploadedData.splice(uploadedData.length, 0, selectedItem);
    }
    const updatedData = [...uploadedData];
    let obj: any = {};
    updatedData.forEach((item, i) => {
      const additionalPropKey = item?.workflowStepDto?.id
        ? item?.workflowStepDto?.id
        : item?.id;
      obj[additionalPropKey] = i + 1;
    });

    // let obj: any = {};
    // updatedData.forEach((item, i) => {
    //   const additionalPropKey = item?.workflowStepDto?.id
    //     ? item?.workflowStepDto?.id
    //     : item?.id;
    //   obj[additionalPropKey] = i === index ? 1 : i < index ? i + 2 : i + 1;
    // });
    const request = {
      order: obj,
    };
    reorderSteps(request);
  };

  const reorderSteps = async (request: any) => {
    await httpPut(
      `${OPTIVAL_CONSTANTS.API_URLS.REORDER_STEP}/${workflowData?.id}/${versionData?.id}`,
      request
    ).then(
      (response) => {
        if (response && response.data && response.data.error) {
          handleAlertBar("error", response.data.message);
        } else {
          console.log("reorder response-->", response.data);
          setInitialUploadedData(response.data?.steps);
          setUploadedData(response.data?.steps);
        }
      },
      (error) => {}
    );
  };

  const renderFilePreview = (index: number) => {
    const uploadedItem = uploadedData[index]?.mediaItemDto
      ? uploadedData[index].mediaItemDto
      : uploadedData[index]?.workflowStepDto &&
        uploadedData[index].workflowStepDto?.mediaItems
      ? uploadedData[index].workflowStepDto?.mediaItems[0]
      : uploadedData[index]?.mediaItems
      ? uploadedData[index].mediaItems[0]
      : null;

    if (!uploadedItem || !uploadedItem.url) return;

    const fileType = uploadedItem?.type?.split("/")[0];

    return (
      <>
        {fileType === "image" && (
          <>
            <img
              src={uploadedItem.url}
              alt="Selected File"
              style={{ maxWidth: "147px", maxHeight: "90px" }}
            />
          </>
        )}
        {fileType === "application" && uploadedItem.type.includes("pdf") && (
          <>
            <PictureAsPdfIcon
              color="primary"
              style={{ width: "147px", height: "90px" }}
            />
            {/* <Typography variant="bodySmall">{uploadedItem.name}</Typography> */}
          </>
        )}
        {fileType === "video" && (
          <>
            <video
              controls
              width="147px"
              height="90px"
              style={{ objectFit: "cover", maxHeight: "90px" }}
            >
              <source src={uploadedItem.url} type={uploadedItem.type} />
              Your browser does not support the video tag.
            </video>
          </>
        )}
        {fileType !== "image" &&
          fileType !== "application" &&
          fileType !== "video" && <></>}
      </>
    );
  };

  const nextButton = () => {
    if (
      workflowData?.name?.trim() !== "" ||
      workflowStepDetail?.workflowTitle
    ) {
      if (audioObj) {
        audioObj.pause();
      }
      if (activeStep === 0) {
        createNewWorkflow();
        if (uploadedData.length > 0) {
          setInitialUploadedData(uploadedData);
        }
      }
      if (activeStep === 1) {
        translateLanguage();
      }
      setTabValue(CREATE_WF_CONSTANTS.ENGLISH.toUpperCase());
      seteditStepDetail({});
    } else {
      handleAlertBar("error", t("opva.titleDescriptionNotErrorMsg"));
    }
  };
  // const introductionButton = () => {
  //   setActiveStep((prevStep) => prevStep - 2);
  //   setInitialWorkflowStepDetail(workflowStepDetail);
  // };

  // const stepButton = () => {
  //   setActiveStep((prevStep) => prevStep - 1);
  //   setInitialUploadedData(uploadedData);
  // };
  const handleSaveWorkflow = () => {
    handleAlertBar("success", t("opva.workflowSavedSuccessMsg"));
    navigate("/instrukt");
  };
  const submitButton = async () => {
    await httpPut(
      `${OPTIVAL_CONSTANTS.API_URLS.SUBMIT_FOR_REVIEW}/${versionData?.id}`,
      versionData?.id
    ).then(
      (response) => {
        if (response && response.data && !response.data.error) {
          handleAlertBar(
            "success",
            t("opva.workflowSubmitReviewSuccessMsg")
          );
          navigate("/instrukt");
        } else {
          handleAlertBar(
            "error",
            response?.data?.message || t("opva.serviceUnavailable")
          );
        }
      },
      (error) => {}
    );
  };
  const handlePublishWorkflow = () => {
    const urlParams = {
      versionId: versionData?.id || 0,
    };
    const queryParams = {
      publish: true,
    };
    httpPut(
      updateURLParams(OPTIVAL_CONSTANTS.API_URLS.PUBLISH_WORKFLOW, urlParams),
      null,
      queryParams
    ).then(
      (response) => {
        if (response && response.data && !response.data.error) {
          handleAlertBar(
            "success",
            t("opva.workflowPublishedSuccessMsg")
          );
          navigate("/instrukt");
        } else {
          handleAlertBar(
            "error",
            response?.data?.message || t("opva.serviceUnavailable")
          );
        }
      },
      (error) => {}
    );
  };
  const previousButton = () => {
    if (activeStep === 2) {
      setInitialUploadedData(uploadedData);
      setUploadedData(uploadedData);
      setEditContentDetail("");
      seteditStepDetail({});
    } else if (activeStep === 1) {
      if (workflowStepDetail?.workflowSelectedFile?.type) {
        workflowStepDetail.workflowSelectedFile = workflowData?.url;
      }
      workflowStepDetail.workflowTitle = workflowData?.name;
      workflowStepDetail.workflowDescription = workflowData?.description;
      setTabValue(CREATE_WF_CONSTANTS.ENGLISH.toUpperCase());
      setInitialWorkflowStepDetail(workflowStepDetail);
    }
    setActiveStep((prevStep) => prevStep - 1);
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <div className="bg-light-blue px-4 pt-1">
        <Breadcrumb
          pathList={[
            { name: t("opva.instrukt"), path: "instrukt" },
            {
              name:
                Props?.isEdit || Props?.isPublish
                  ? Props?.isPublish
                    ? t("opva.publishWorkflowCreation")
                    : t("opva.updateWorkflowCreation")
                  : t("opva.newWorkflowCreation"),
              path: "newworkflow",
            },
          ]}
        />
        <div className="d-flex align-items-center pb-4">
          <Link
            component="button"
            title={t("opva.backToWorkflow")}
            className="me-2"
            onClick={backToWorkflow}
          >
            <KeyboardArrowLeftIcon fontSize="large" />
          </Link>
          <Typography variant="h4">
            {Props?.isEdit || Props?.isPublish
              ? Props?.isPublish
                ? `${t("opva.publishWorkflowCreation")}`
                : `${t("opva.updateWorkflowCreation")}`
              : `${t("opva.newWorkflowCreation")}`}
          </Typography>
          <Box flex={1} />

          <Box
            sx={{
              width: "35%",
            }}
          >
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step
                  key={index}
                  active={index === activeStep}
                  completed={index < activeStep}
                >
                  <StepLabel>{t(label)}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </div>
      </div>
      <div>
        {activeStep === 2 && (
          <Box sx={{ width: "100%", typography: "body1" }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              className="tabs-container"
            >
              {tabList.map((data: any) => (
                <Tab
                  key={data?.display_name}
                  value={data?.language_name}
                  label={data?.display_name}
                  sx={{ textTransform: "none" }}
                />
              ))}
            </Tabs>
          </Box>
        )}
      </div>
      <div>
        {activeStep === 0 ? (
          <IntroWorkflow
            tagList={tagList}
            workflowStepData={workflowStepData}
            getAudioPlayURL={getAudioPlayURL}
            initialWorkflowData={workflowInitialStepDetail}
            tabValue={tabValue}
            isUpdated={false}
          />
        ) : activeStep === 1 ? (
          <StepWorkflow
            initialStepData={initialUploadedData}
            editStepDetail={editStepDetail}
            deleteStepDetail={deleteStepDetail}
            StepTwoData={StepTwoData}
            renderFilePreview={renderFilePreview}
            reorderIconClick={reorderIconClick}
            deleteIconClick={handleAlertDialogOpen}
            audioObj={audioObj}
            // stepButton={stepButton}
            workflowData={workflowData}
            versionData={versionData}
            editIconClick={editIconClick}
            getAudioPlayURL={getAudioPlayURL}
            isUpdated={false}
            handleDragStart={handleDragStart}
            handleDragEnd={handleDragEnd}
            handleDragOver={handleDragOver}
            handleDrop={handleDrop}
            draggingItem={draggingItem}
          />
        ) : (
          <ReviewWorkflow
            uploadedData={uploadedData}
            renderFilePreview={renderFilePreview}
            reorderIconClick={reorderIconClick}
            deleteIconClick={handleAlertDialogOpen}
            // stepButton={stepButton}
            workflowStepDetail={workflowData}
            tagName={tagName}
            // introductionButton={introductionButton}
            editIconClick={handleEditWorkflowModelOpen}
            handleDragStart={handleDragStart}
            handleDragEnd={handleDragEnd}
            handleDragOver={handleDragOver}
            handleDrop={handleDrop}
            draggingItem={draggingItem}
            tabValue={tabValue}
          />
        )}
      </div>
      {checkRole([
        OPTIVAL_CONSTANTS.CONTENT_ADMIN,
        OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
      ]) && (
        <div className="px-5">
          <AssignWorkflow
            title={t("opva.assignToEditor")}
            workflowDetail={versionData}
            sentAssignUserIds={(data) => {
              setAssignUserIds(data);
              if (data && data.length > 0) {
                setIsPublishWorkflow(true);
              } else {
                setIsPublishWorkflow(false);
              }
            }}
            doPublishWorkflow={(data) => setIsPublishWorkflow(data)}
          />
        </div>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        className="footer px-5 py-4"
      >
        {activeStep !== 0 ? (
          <Button
            variant="outlined"
            sx={{ width: "15%" }}
            onClick={handleUnsavedChangesAlertDialogOpen}
          >
            {t("opva.previous")}
          </Button>
        ) : (
          <div />
        )}

        {activeStep === 0 || activeStep === 1 ? (
          <Button
            variant="contained"
            sx={{ width: "15%" }}
            className="py-2"
            onClick={nextButton}
            disabled={isnextDisable}
          >
            {t("opva.next")}
          </Button>
        ) : (
          <>
            {checkRole([OPTIVAL_CONSTANTS.CONTENT_EDITOR]) && (
              <Button
                variant="contained"
                sx={{ width: "15%" }}
                className="py-2"
                onClick={(e) => setIsWorkflowSubmitDialog(true)}
              >
                {t("opva.submit")}
              </Button>
            )}
            {checkRole([
              OPTIVAL_CONSTANTS.CONTENT_ADMIN,
              OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
              OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
            ]) && (
              <div className="d-flex align-items-center justify-content-end w-50">
                <Button
                  variant="contained"
                  className="py-2 me-4"
                  onClick={handleSaveWorkflow}
                >
                  {t("opva.save")}
                </Button>
                <Button
                  variant="contained"
                  sx={{ width: "20%" }}
                  className="py-2"
                  onClick={handlePublishWorkflow}
                  disabled={isPublishBtnDisable || isPublishWorkflow}
                >
                  {t("opva.publish")}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
      <Dialog
        maxWidth="sm"
        disableEscapeKeyDown={true}
        TransitionComponent={DialogTransition}
        open={isEditWorkflowOpen}
        onClose={handleEditWorkflowModelClose}
        aria-labelledby="edit-workflow-title"
        aria-describedby="edit-workflow-description"
        className="edit-workflow-model"
        sx={{
          "& .MuiDialog-container": {
            alignItems: "flex-start",
            justifyContent: "end",
          },
          "& .MuiDialog-paper": {
            margin: 0,
            maxHeight: "100%",
            height: "100%",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle id="edit-workflow-title">
            {(editContentDetail === "Workflow" && t("opva.workflowDetails")) ||
              (editContentDetail === "Steps" && t("opva.save"))}
          </DialogTitle>
          <Link
            aria-label="Close"
            component="button"
            title="Close"
            onClick={(e) => handleEditWorkflowModelClose(e, "Close")}
            className="pe-3"
          >
            <CloseIcon />
          </Link>
        </Box>
        <DialogContent>
          {(editContentDetail === "Workflow" && (
            <IntroWorkflow
              tagList={tagList}
              workflowStepData={workflowStepData}
              initialWorkflowData={workflowInitialStepDetail}
              isUpdated={true}
              tabValue={tabValue}
              getAudioPlayURL={getAudioPlayURL}
            />
          )) ||
            (editContentDetail === "Steps" && (
              <StepWorkflow
                initialStepData={initialUploadedData}
                editStepDetail={editStepDetail}
                deleteStepDetail={deleteStepDetail}
                StepTwoData={StepTwoData}
                renderFilePreview={renderFilePreview}
                reorderIconClick={reorderIconClick}
                deleteIconClick={handleAlertDialogOpen}
                workflowData={workflowData}
                versionData={versionData}
                editIconClick={editIconClick}
                getAudioPlayURL={getAudioPlayURL}
                isUpdated={true}
                tabValue={tabValue}
              />
            ))}
        </DialogContent>
        <DialogActions className="justify-content-start">
          <Button
            variant="contained"
            sx={{ width: "40%" }}
            onClick={updateButton}
            disabled={isSaveDisable}
          >
            {t("opva.save")}
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        open={isWorkflowSubmitDialog}
        title={t("opva.readyForSubmission")}
        description={t("opva.comfirmationSubmitWorkflowReviewMsg")}
        submitBtnText={t("opva.submitForReview")}
        handleSubmit={() => {
          setIsWorkflowSubmitDialog(false);
          submitButton();
        }}
        handleClose={() => setIsWorkflowSubmitDialog(false)}
      ></AlertDialog>
      <AlertDialog
        open={isAlertDialog}
        title={t("opva.deleteStep")}
        description={t("opva.confirmationDeleteStepWorkflowMsg")}
        submitBtnText={t("opva.delete")}
        handleSubmit={deleteIconClick}
        handleClose={handleAlertDialogClose}
      ></AlertDialog>

      <Dialog fullWidth open={isUnsavedChangesAlertDialog} onClose={()=> setIsUnsavedChangesAlertDialog(false)}>
        <DialogContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant='h5'>{t("opva.unsavedChanges")}</Typography>
            <IconButton aria-label="close" onClick={()=> setIsUnsavedChangesAlertDialog(false)} color='primary'>
              <CloseIcon />
            </IconButton>
          </div>
          <Box>
            <div style={{ display: "flex", flexDirection: "column" }} className='mt-3'>
            <Typography variant='bodyMedium'>{t("opva.unsavedChangesDescription")}</Typography>
        </div>
      </Box>
        </DialogContent>
        <DialogActions className="dialog-btn">
          <Button
              sx={{textTransform: 'none'}}
              onClick={() => handleUnsavedChangesAlertDialogClose()}
              color="primary"
              variant="outlined"
            >
              {t("opva.leaveWithoutSaving")}
          </Button>
          <Button
            sx={{textTransform: 'none'}}
            onClick={() => handleSavedChanges()}
            color="primary"
            variant="contained"
          >
            {t("opva.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
