import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import AddIcon from "@mui/icons-material/Add";
import { find } from "lodash";
import {
  TableDataActionsModel,
  UserAssignedRolesListModel,
  UserListModel,
  UserListProps,
} from "../../models/GroupManagementModels";
import { GroupManagementConstants, useGroupManagementUtils } from "../../constants/GroupManagementConstants";
import { Order, TableHeadCell } from "../../../../shared/models/TableModels";
import { useAPI } from "../../../../shared/services/api/API";
import { AuthenticationContext } from "../../../../shared/Contexts";
import { OPTIVAL_CONSTANTS } from "../../../../shared/Constants";
import { TableLoading } from "../../../../shared/components/skeleton/table-loading/TableLoading";
import { tableSorting, updateURLParams } from "../../../../shared/utils/Utils";
import { AppDispatch, RootState } from "../../../../shared/Store";
import {
  setAddUserList,
  setRemoveUserList,
} from "../../../../shared/reducers/UserSelectedReducer";
import { useTranslation } from "react-i18next";

export const UserList = (props: UserListProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const routeParams = new URLSearchParams(location.search);
  const { authUserDetails } = useContext(AuthenticationContext);
  const { httpGet, handleAlertBar } = useAPI();
  const [isUserListLoading, setIsUserListLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const userSelectState = useSelector((state: RootState) => state.userSelect);
  const [tableDataActions, setTableDataActions] = useState({
    page: 0,
    perPageRows: 10,
    order: "desc",
    orderBy: "firstName",
    searchText: "",
  } as TableDataActionsModel);
  const [userByOrgList, setUserByOrgList] = useState<UserListModel[]>([]);
  const [filteredUserDataList, setFilteredUserDataList] = useState<
    UserListModel[]
  >([]);
  const [checkedUserManagement, setCheckedUserManagement] = useState<
  UserListModel[]
  >([]);
  const { getUsersTableHeaders } = useGroupManagementUtils();

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const updateProperty = {
      perPageRows: parseInt(event.target.value, 10),
      page: 0,
    };
    setTableDataActions((prevState) => ({
      ...prevState,
      ...updateProperty,
    }));
  };

  const handlePaginationChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    setTableDataActions((prevState) => ({
      ...prevState,
      page: newPage,
    }));
  };

  const handleSorting = (property: string) => {
    const isAsc =
      tableDataActions.orderBy === property && tableDataActions.order === "asc";
    const updateProperty = {
      order: isAsc ? "desc" : ("asc" as Order),
      orderBy: property,
    };
    setTableDataActions((prevState) => ({
      ...prevState,
      ...updateProperty,
    }));
  };

  const handleAddUserData = (userData: UserListModel, index: number) => {
    userByOrgList.splice(index, 1);
    setUserByOrgList([...userByOrgList]);
    dispatch(setAddUserList([userData]));
  };

  const handleAddBulkUserData = async() => {
    const userByOrgUpdatedList = userByOrgList?.filter((user: any) => !checkedUserManagement?.some((item: any) => item?.id === user?.id) );
    setUserByOrgList(userByOrgUpdatedList);
    dispatch(setAddUserList(checkedUserManagement));
    setCheckedUserManagement([]);
  }

  const fetchUsersByOrganization = () => {
    setIsUserListLoading(true);
    const urlParams = {
      organizationId: authUserDetails.organizationId,
      groupId:
        routeParams && routeParams.get("groupId")
          ? routeParams.get("groupId")
          : 0,
    };
    const queryParams = {
      page: 0,
      size: 1000,
      sortColumn: "firstName",
      isDescending: true,
      roleIds: "",
    };
    httpGet(
      updateURLParams(
        props.isEditMode
          ? OPTIVAL_CONSTANTS.API_URLS.GET_EMPLOYEES_NOT_GROUP
          : OPTIVAL_CONSTANTS.API_URLS.GET_EMPLOYEES_BY_ORGANIZATION,
        urlParams
      ),
      queryParams
    ).then(
      (response) => {
        if (response && response?.data && !response.data?.error) {
          setUserByOrgList(response.data);
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
        setIsUserListLoading(false);
      },
      (error) => {
        setIsUserListLoading(false);
      }
    );
  };

  useMemo(() => {
    const propertyType =
      tableDataActions.orderBy === "users" ? "number" : "string";
    const sortedList = tableSorting(
      userByOrgList,
      tableDataActions.order,
      tableDataActions.orderBy,
      propertyType
    );
    setUserByOrgList(sortedList);
    setFilteredUserDataList(
      sortedList.slice(
        tableDataActions.page * tableDataActions.perPageRows,
        tableDataActions.page * tableDataActions.perPageRows +
          tableDataActions.perPageRows
      )
    );
  }, [tableDataActions, userByOrgList]);

  useEffect(() => {
    if (
      userSelectState &&
      userSelectState.removeUserList &&
      userSelectState.removeUserList.length > 0
    ) {
      const findUser = find(userByOrgList, [
        "id",
        userSelectState.removeUserList[0].id,
      ]);
      if (!findUser) {
        userByOrgList.unshift(...userSelectState.removeUserList);
        setUserByOrgList([...userByOrgList]);
      }
      dispatch(setRemoveUserList([]));
    }
  }, [userSelectState]);

  // Call fetchData on component mount
  useEffect(() => {
    if (authUserDetails && authUserDetails.organizationId) {
      fetchUsersByOrganization();
    }
  }, [authUserDetails]);

  function allUserManagementListChecked() {
    if (
      checkedUserManagement.length === 0 &&
      userByOrgList.length === 0
    )
      return false;
    for (let user of userByOrgList) {
      const isPresent = checkedUserManagement.some(
        (data: any) => data.id === user.id
      );

      if (!isPresent) {
        return false;
      }
    }
    return true;
  }

  const handleAllCheckBoxForUserMgmtList = (event: any) => {
    if (event.target.checked) {
      const uniqueUser: UserListModel[] = [];
      userByOrgList.forEach((user: UserListModel) => {
        const index = checkedUserManagement.findIndex(
          (checkedUser: UserListModel) =>
            checkedUser.id === user.id
        );
        if (index === -1) {
          uniqueUser.push(user);
        }
      });
      setCheckedUserManagement([
        ...checkedUserManagement,
        ...uniqueUser,
      ]);
    } else {
      setCheckedUserManagement([]);
    }
  };

  const checkedUserManagementList = (data: any): boolean => {
    if (!checkedUserManagement.length) {
      return false;
    }
    const arr = checkedUserManagement.filter(
      (res: any) => res.id === data.id
    );
    return arr.length > 0 ? true : false;
  };

  const handleCheckBoxForUser = (event: any, data: any) => {
    if (event.target.checked) {
      setCheckedUserManagement([...checkedUserManagement, data]);
    } else {
      const index = checkedUserManagement.findIndex(
        (workflow: any) => workflow.id === data.id
      );
      checkedUserManagement.splice(index, 1);

      setCheckedUserManagement([...checkedUserManagement]);
    }
  };

  return (
    <div className="user-list-container">
      <Grid container alignItems="center" className="pt-2 pb-2">
          <Grid item xs md lg={6} className="d-flex align-items-center">
            <Typography variant="bodyMedium">{props.title}</Typography>
          </Grid>
          <Grid
            item
            xs
            md
            lg={6}
            className="d-flex align-items-center justify-content-end"
          >
            <Button
              variant="contained"
              size="medium"
              className="px-4 ms-4"
              onClick={handleAddBulkUserData}
              disabled={checkedUserManagement?.length === 0}
            >
              {t("opva.addUsers")}
            </Button>
          </Grid>
        </Grid>
      <TableContainer className="user-list-table-container mt-2">
        <Table
          stickyHeader
          sx={{ width: "100%" }}
          size="medium"
          aria-label="User List Table"
        >
          <TableHead>
            <TableRow key="group-mgmt-table-header">
              <TableCell size="small" padding="checkbox">
                <Checkbox
                      checked={allUserManagementListChecked()}
                      onChange={(e) => handleAllCheckBoxForUserMgmtList(e)}
                  />
                </TableCell>
              {getUsersTableHeaders().map(
                (headCell: TableHeadCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={
                      tableDataActions.orderBy === headCell.sortingProperty
                        ? tableDataActions.order
                        : false
                    }
                    align={headCell.position}
                    sx={{
                      width: headCell.width + "%",
                    }}
                  >
                    {headCell.sorting ? (
                      <TableSortLabel
                        active={
                          tableDataActions.orderBy === headCell.sortingProperty
                        }
                        direction={
                          tableDataActions.orderBy === headCell.sortingProperty
                            ? tableDataActions.order
                            : "asc"
                        }
                        onClick={(event) =>
                          handleSorting(headCell.sortingProperty)
                        }
                      >
                        {headCell.label}
                        {tableDataActions.orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {tableDataActions.order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          {isUserListLoading ? (
            <TableLoading
              column={getUsersTableHeaders().length}
            />
          ) : (
            <TableBody>
              {filteredUserDataList && filteredUserDataList.length > 0 ? (
                filteredUserDataList.map(
                  (data: UserListModel, index: number) => (
                    <TableRow key={index}>
                      <TableCell size="small" padding="checkbox">
                          <Checkbox
                            checked={checkedUserManagementList(data)}
                            onChange={(e) => handleCheckBoxForUser(e, data)}
                          />
                        </TableCell>
                      <TableCell>
                        {data.firstName} {data.lastName}
                      </TableCell>
                      <TableCell>{data?.employeeId}</TableCell>
                      <TableCell>{data.mail}</TableCell>
                      <TableCell>
                        {data?.assignedRoles && data?.assignedRoles.length > 0
                          ? data.assignedRoles.map(
                              (
                                roleData: UserAssignedRolesListModel,
                                index: number,
                                roleList: UserAssignedRolesListModel[]
                              ) => roleData.name + ","
                            )
                          : ""}
                      </TableCell>
                      <TableCell>{data?.position}</TableCell>
                      <TableCell align="center">
                        <Link
                          component="button"
                          title="Add User"
                          onClick={(e) => handleAddUserData(data, index)}
                        >
                          <AddIcon />
                        </Link>
                      </TableCell>
                    </TableRow>
                  )
                )
              ) : (
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={getUsersTableHeaders().length}
                  >
                    {t("opva.noUserDataAvailable")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <div className="d-flex align-items-center justify-content-end">
        <TablePagination
          component="div"
          className="pagination-container"
          count={userByOrgList.length}
          page={tableDataActions.page}
          rowsPerPage={tableDataActions.perPageRows}
          onPageChange={handlePaginationChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};
