import "./GroupManagement.scss";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Input,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Breadcrumb } from "../../breadcrumb/components/Breadcrumb";
import { GroupManagementConstants, useGroupManagementUtils } from "../constants/GroupManagementConstants";
import { Order, TableHeadCell } from "../../../shared/models/TableModels";
import {
  GroupMgmtListModel,
  TableDataActionsModel,
} from "../models/GroupManagementModels";
import { useAPI } from "../../../shared/services/api/API";
import { SpinnerContext } from "../../../shared/SpinnerContext";
import { OPTIVAL_CONSTANTS } from "../../../shared/Constants";
import { TableLoading } from "../../../shared/components/skeleton/table-loading/TableLoading";
import { tableSorting, updateURLParams } from "../../../shared/utils/Utils";
import { AppDispatch } from "../../../shared/Store";
import { setPassDataState } from "../../../shared/reducers/PassDataReducer";
import { useTranslation } from "react-i18next";
import { AlertDialog } from "../../../shared/components/alert-dialog/AlertDialog";

export const GroupManagement = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { httpGet, httpDelete, handleAlertBar } = useAPI();
  const { isSpinnerShow } = useContext(SpinnerContext);
  const [tableDataActions, setTableDataActions] = useState({
    page: 0,
    perPageRows: 10,
    order: "desc",
    orderBy: "name",
    searchText: "",
  } as TableDataActionsModel);
  const [groupMgmtList, setGroupMgmtList] = useState<GroupMgmtListModel[]>([]);
  const [filteredGroupMgmtData, setFilteredGroupMgmtData] = useState<
    GroupMgmtListModel[]
  >([]);
  const [checkedGroupManagement, setCheckedGroupManagement] = useState<
  GroupMgmtListModel[]
>([]);
  const [searchGroupsText, setSearchGroupsText] = useState("");
  const { getGroupManagementHeaders } = useGroupManagementUtils();
  const [isAlertDialog, setIsAlertDialog] = useState(false);
  const [isAlertDialogBulkDelete, setIsAlertDialogBulkDelete] = useState(false);
  const [groupId, setGroupId] = useState<number>(0);

  const handleAlertDialogOpen = (groupId: number) => {
    setGroupId(groupId);
    setIsAlertDialog(true);
  };

  const handleAlertDialogClose = () => {
    setGroupId(0);
    setIsAlertDialog(false);
  };

  const handleAlertDialogOpenBulkDelete = () => {
    setIsAlertDialogBulkDelete(true);
  };

  const handleAlertDialogCloseBulkDelete = () => {
    setIsAlertDialogBulkDelete(false);
  };

  const backToOrganisation = () => {
    navigate("/management");
  };

  const goToAddGroup = () => {
    navigate("/add-group");
  };

  const ActionTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      border: "1px solid #dadde9",
      padding: "1rem",
    },
  }));

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const updateProperty = {
      perPageRows: parseInt(event.target.value, 10),
      page: 0,
    };
    setTableDataActions((prevState) => ({
      ...prevState,
      ...updateProperty,
    }));
  };

  const handlePaginationChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    setTableDataActions((prevState) => ({
      ...prevState,
      page: newPage,
    }));
  };

  const handleSorting = (property: string) => {
    const isAsc =
      tableDataActions.orderBy === property && tableDataActions.order === "asc";
    const updateProperty = {
      order: isAsc ? "desc" : ("asc" as Order),
      orderBy: property,
    };
    setTableDataActions((prevState) => ({
      ...prevState,
      ...updateProperty,
    }));
  };

  const handleSearchByGroup = (value: string) => {
    if (!value) {
      setGroupMgmtList([...groupMgmtList]);
    } else {
      const filteredData = groupMgmtList.filter(
        (groupData: GroupMgmtListModel) =>
          groupData.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredGroupMgmtData(
        filteredData.slice(
          tableDataActions.page * tableDataActions.perPageRows,
          tableDataActions.page * tableDataActions.perPageRows +
            tableDataActions.perPageRows
        )
      );
    }
  };

  const fetchGroupMgmtList = () => {
    const queryParams = {
      page: 0,
      size: 1000,
      sortColumn: "name",
      isDescending: true,
      searchString: "",
    };
    httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_GROUP_DATA, queryParams).then(
      (response) => {
        if (response && response?.data && !response.data?.error) {
          setGroupMgmtList(response.data);
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  };

  const handleDeleteGroup= async() => {
    const urlParams = {
      groupId: groupId
    };
    await httpDelete(
      updateURLParams(OPTIVAL_CONSTANTS.API_URLS.UPDATE_GROUP, urlParams),
    ).then(
      (response) => {
        if (response?.status === 200) {
          handleAlertBar(
            "success",
            t("opva.groupDeletedSuccessMsg")
          );
          fetchGroupMgmtList();
          handleAlertDialogClose();
        } 
        else {
        handleAlertBar("error", t("opva.serviceUnavailable"));
      }
      },
      (error) => {}
    );
  };

  function allGroupManagementListChecked() {
    if (
      checkedGroupManagement.length === 0 &&
      groupMgmtList.length === 0
    )
      return false;
    for (let group of groupMgmtList) {
      const isPresent = checkedGroupManagement.some(
        (data: any) => data.id === group.id
      );

      if (!isPresent) {
        return false;
      }
    }
    return true;
  }

  const handleAllCheckBoxForGroupMgmtList = (event: any) => {
    if (event.target.checked) {
      const uniqueGroup: GroupMgmtListModel[] = [];
      groupMgmtList.forEach((group: GroupMgmtListModel) => {
        const index = checkedGroupManagement.findIndex(
          (checkedGroup: GroupMgmtListModel) =>
            checkedGroup.id === group.id
        );
        if (index === -1) {
          uniqueGroup.push(group);
        }
      });
      setCheckedGroupManagement([
        ...checkedGroupManagement,
        ...uniqueGroup,
      ]);
    } else {
      setCheckedGroupManagement([]);
    }
  };

  const checkedGroupManagementList = (data: any): boolean => {
    if (!checkedGroupManagement.length) {
      return false;
    }
    const arr = checkedGroupManagement.filter(
      (res: any) => res.id === data.id
    );
    return arr.length > 0 ? true : false;
  };

  const handleCheckBoxForGroup = (event: any, data: any) => {
    if (event.target.checked) {
      setCheckedGroupManagement([...checkedGroupManagement, data]);
    } else {
      const index = checkedGroupManagement.findIndex(
        (workflow: any) => workflow.id === data.id
      );
      checkedGroupManagement.splice(index, 1);

      setCheckedGroupManagement([...checkedGroupManagement]);
    }
  };

  const deleteGroupInBulk = async() => {
    const payload = checkedGroupManagement?.map((group: any) => group?.id);
    await httpDelete(
      OPTIVAL_CONSTANTS.API_URLS.DELETE_MULTIPLE_GROUP, payload,
    ).then(
      (response) => {
          if (response?.status === 200) {
            handleAlertBar(
              "success",
              t("opva.groupDeletedSuccessMsg")
            );
            fetchGroupMgmtList();
            handleAlertDialogCloseBulkDelete();
          } 
          else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      },
      (error) => {}
    );
  }

  useMemo(() => {
    const propertyType =
      tableDataActions.orderBy === "assignedEmployeesCount"
        ? "number"
        : "string";
    const sortedList = tableSorting(
      groupMgmtList,
      tableDataActions.order,
      tableDataActions.orderBy,
      propertyType
    );
    setGroupMgmtList(sortedList);
    setFilteredGroupMgmtData(
      sortedList.slice(
        tableDataActions.page * tableDataActions.perPageRows,
        tableDataActions.page * tableDataActions.perPageRows +
          tableDataActions.perPageRows
      )
    );
  }, [tableDataActions, groupMgmtList]);

  useEffect(() => {
    handleSearchByGroup(searchGroupsText);
  }, [searchGroupsText]);

  // Call fetchData on component mount
  useEffect(() => {
    fetchGroupMgmtList();
  }, []);

  const renderTruncateDescription = (description: string, whereTo: string): string => {
    return description?.length > 32
      ? whereTo === "display"
        ? description.substring(0, 32) + "..."
        : description
      : whereTo === "display"
      ? description
      : "";
  };

  return (
    <div className="group-mgmt-container">
      <div className="bg-light-blue px-4 pt-2 mb-3">
        <Breadcrumb
          pathList={[
            { name: t("opva.management"), path: "management" },
            { name: t("opva.groupManagement"), path: "group-management" },
          ]}
        />
        <Grid container alignItems="center" className="pt-2 pb-4">
          <Grid item xs md lg={6} className="d-flex align-items-center">
            <Link
              component="button"
              title={t("opva.backToManagement")}
              className="me-2"
              onClick={backToOrganisation}
            >
              <KeyboardArrowLeftIcon fontSize="large" />
            </Link>
            <Typography variant="h3" className="mb-0">
              {t("opva.groupManagement")}
            </Typography>
          </Grid>
          <Grid
            item
            xs
            md
            lg={6}
            className="d-flex align-items-center justify-content-end"
          >
            <Input
              id="searchByGroup"
              placeholder={t("opva.searchByGroup")}
              value={searchGroupsText}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon color="primary" />
                </InputAdornment>
              }
              endAdornment={
                searchGroupsText && (
                  <InputAdornment position="end">
                    <Link component="button" title={t("opva.clear")}>
                      <CloseIcon
                        fontSize="small"
                        onClick={() => setSearchGroupsText("")}
                      />
                    </Link>
                  </InputAdornment>
                )
              }
              aria-describedby="standard-weight-helper-text"
              onChange={(event) => setSearchGroupsText(event.target.value)}
            />
            <Button
              variant="contained"
              size="medium"
              className="px-4 ms-4"
              onClick={goToAddGroup}
            >
              {t("opva.addNewGroup")}
            </Button>
            <Button
              variant="contained"
              size="medium"
              className="px-4 ms-4"
              onClick={handleAlertDialogOpenBulkDelete}
              disabled={checkedGroupManagement?.length === 0}
            >
              {t("Delete Groups")}
            </Button>
          </Grid>
        </Grid>
      </div>
      <Box
        sx={{ width: "100%", typography: "body1" }}
        className="group-mgmt-content px-5"
      >
        <TableContainer className="table-container">
          <Table
            stickyHeader
            sx={{ width: "100%" }}
            size="medium"
            aria-label="Group Management table"
          >
            <TableHead>
              <TableRow key="group-mgmt-table-header">
              <TableCell size="small" padding="checkbox">
                <Checkbox
                      checked={allGroupManagementListChecked()}
                      onChange={(e) => handleAllCheckBoxForGroupMgmtList(e)}
                  />
                </TableCell>
                {getGroupManagementHeaders().map(
                  (headCell: TableHeadCell) => (
                    <TableCell
                      key={headCell.id}
                      sortDirection={
                        tableDataActions.orderBy === headCell.sortingProperty
                          ? tableDataActions.order
                          : false
                      }
                      align={headCell.position}
                      sx={{
                        width: headCell.width + "%",
                      }}
                    >
                      {headCell.sorting ? (
                        <TableSortLabel
                          active={
                            tableDataActions.orderBy ===
                            headCell.sortingProperty
                          }
                          direction={
                            tableDataActions.orderBy ===
                            headCell.sortingProperty
                              ? tableDataActions.order
                              : "asc"
                          }
                          onClick={(event) =>
                            handleSorting(headCell.sortingProperty)
                          }
                        >
                          {headCell.label}
                          {tableDataActions.orderBy === headCell.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {tableDataActions.order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        headCell.label
                      )}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            {isSpinnerShow ? (
              <TableLoading
                column={getGroupManagementHeaders().length}
              />
            ) : (
              <TableBody>
                {filteredGroupMgmtData && filteredGroupMgmtData.length > 0 ? (
                  filteredGroupMgmtData.map(
                    (data: GroupMgmtListModel, index: number) => (
                      <TableRow key={index}>
                        <TableCell size="small" padding="checkbox">
                        <Checkbox
                            checked={checkedGroupManagementList(data)}
                            onChange={(e) => handleCheckBoxForGroup(e, data)}
                          />
                        </TableCell>
                        <TableCell>{data.name}</TableCell>
                        <TableCell title={renderTruncateDescription(
                                    data?.description,
                                    "description"
                                  )}>{renderTruncateDescription(data?.description, "display")}</TableCell>
                        <TableCell>
                          {data.createdAt &&
                            new Date(data.createdAt).toLocaleDateString()}
                        </TableCell>
                        <TableCell>{data?.createdBy}</TableCell>
                        <TableCell align="center">
                          {data?.assignedEmployeesCount}
                        </TableCell>
                        <TableCell align="center">
                          <ActionTooltip
                            arrow
                            slotProps={{
                              popper: {
                                sx: {
                                  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                    {
                                      marginTop: "0px",
                                    },
                                },
                              },
                            }}
                            // onClick={(e) => {
                            //   dispatch(setPassDataState(data));
                            //   navigate("/edit-group?groupId=" + data.id, {
                            //     state: { groupData: data },
                            //   });
                            // }}
                            title={
                              <div className="d-flex flex-column">
                                <Link
                                  // component="button"
                                  title="Edit"
                                  underline="none"
                                  className="d-flex align-items-center"
                                  onClick={(e) => {
                                    dispatch(setPassDataState(data));
                                    navigate("/edit-group?groupId=" + data.id, {
                                      state: { groupData: data },
                                    });
                                  }}
                                >
                                  <EditIcon />
                                  <Typography
                                    variant="labelNormal"
                                    className="ps-2"
                                  >
                                    {t("opva.viewEdit")}
                                  </Typography>
                                </Link>
                                <Link
                                  component="button"
                                  title={t("opva.delete")}
                                  color={"error"}
                                  underline="none"
                                  className="d-flex align-items-center mt-3"
                                  onClick={() => handleAlertDialogOpen(data.id)}
                                >
                                  <DeleteIcon />{" "}
                                  <Typography
                                    color={"error"}
                                    variant="labelNormal"
                                    className="ps-2"
                                  >
                                    {t("opva.delete")}
                                  </Typography>
                                </Link>
                              </div>
                            }
                          >
                            <Link component="button">
                              <MoreHorizIcon />
                            </Link>
                          </ActionTooltip>
                        </TableCell>
                      </TableRow>
                    )
                  )
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={
                        getGroupManagementHeaders().length
                      }
                    >
                      {searchGroupsText
                        ? t("opva.searchGroupDataNotAvailable.")
                        : t("opva.noGroupDataAvailable")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <div className="d-flex align-items-center justify-content-end">
          <TablePagination
            component="div"
            className="pagination-container mb-4"
            count={groupMgmtList.length}
            page={tableDataActions.page}
            rowsPerPage={tableDataActions.perPageRows}
            onPageChange={handlePaginationChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
        <AlertDialog
          open={isAlertDialog}
          title={t("opva.deleteGroup")}
          description={t("opva.confirmationMsgDeleteGroup")}
          submitBtnText={t("opva.delete")}
          handleSubmit={handleDeleteGroup}
          handleClose={handleAlertDialogClose}
        ></AlertDialog>
        <AlertDialog
          open={isAlertDialogBulkDelete}
          title={t("opva.deleteGroup")}
          description={t("opva.confirmationMsgDeleteGroup")}
          submitBtnText={t("opva.delete")}
          handleSubmit={deleteGroupInBulk}
          handleClose={handleAlertDialogCloseBulkDelete}
        ></AlertDialog>
      </Box>
    </div>
  );
};
